
interface GetDateUTCProps {
    method: 'string' | 'object',
    settingsUser: any,
    date?: string
}

export default function ConverterUTC({method, settingsUser, date} : GetDateUTCProps): Date | string {
    const convertStringToUTC = (date: string) => {
        const dateString = date;
        const dateParts: string[] = dateString.split(".");        
        return new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
    }

    const currentDate = date ? convertStringToUTC(date) : new Date()

    const currentDateStringUTC = settingsUser.timeZone > 0 ?
        new Date(currentDate.setHours(currentDate.getHours() + Math.abs(settingsUser.timeZone))).toUTCString() 
            : 
        new Date(currentDate.setHours(currentDate.getHours() - Math.abs(settingsUser.timeZone))).toUTCString();

    const currentDateObjectUTC = new Date(Date.parse(currentDateStringUTC) - (settingsUser.currentTimeZone * 60) * 60000);
    
    switch (method) {
        case 'string':
            return currentDateStringUTC;
        case 'object':
            return currentDateObjectUTC;
        default:
            break;
    }
}