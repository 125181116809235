import request from "../../Requests/requests";
import { loadDataElapsedTime } from "../../store/StatisticsStore/ElapsedTimeToTodosReducer";

export const getElapsedTimeToTodos = (payload) => {
    return async dispatch => {
        await request.get('/Statistics/GetTimeByPeriod/', {
            params: {UserId: localStorage.getItem('token'),
            DateStart: payload.DateStart, 
            DateEnd: payload.DateEnd }
        })
        .then(response => dispatch(loadDataElapsedTime(response.data)))
        // .then(data => dispatch(loadDataElapsedTime(data))) 
        
    }
}

// export const getElapsedTimeToTodos = (payload) => {
//     return async dispatch => {
//         await request.get('/Statistics/Completed/', {
//             params: {UserId: localStorage.getItem('token'),
//             DateStart: payload.DateStart, 
//             DateEnd: payload.DateEnd }
//         })
//         .then(response =>{
//             const data = response.data;
//             dispatch(loadData(data.tasks))
//         })
        
//     }
// }
