const defaultState = {
    todos: [],
}

const ADD_TODO = "ADD_TODO"
const REMOVE_TODO = "REMOVE_TODO"
const LOAD_DATA_TODO = "LOAD_DATA_TODO"

export const todosReducer = (state = defaultState, action) => {
    switch(action.type) {
      case ADD_TODO:
        return {...state, todos: [...state.todos, action.payload]} 
      case REMOVE_TODO:
        return {...state, todos: state.todos.filter(todos => todos.id !== action.payload)}
      case LOAD_DATA_TODO:
        return {...state, todos: [...action.payload]}
      default:
        return state
    }
  }

export const addTodoAction = (payload) => ({type: ADD_TODO, payload})
export const removeTodoToday = (payload) => ({type: REMOVE_TODO, payload})
export const loadDataTodo = (payload) => ({type: LOAD_DATA_TODO, payload})