import React from "react"
import './index.css'
import { Link } from 'react-router-dom';
import { ReactComponent as CalendarSvg } from "../../icons/svg/submenu_ic/calendar.svg";
import { ReactComponent as TodaySvg } from "../../icons/svg/submenu_ic/element-1.svg";
import { ReactComponent as IncomingSvg } from "../../icons/svg/submenu_ic/filled.svg";

const DDselectPage = ({updatePageName, arrayPages, arrayLink, currentLocation}) => {
    let arraySvg = [
        <TodaySvg style={{width:'16px', height: '16px'}}/>, 
        <CalendarSvg style={{width:'16px', height: '16px'}}/>, 
        <IncomingSvg style={{width:'16px', height: '16px'}}/>
    ];
    
    const updateInfo = (value, index) => {
        updatePageName({
            pageName: value,
            index: index
        })
    }

    return (
        <div className="selectPage-container">
            <ul className="selectPage-list">
                {
                    arrayPages.map((item, index) => (
                        <li key={index} 
                            className={currentLocation === arrayLink[index] ? 
                                "selectPage-item selected-item" : "selectPage-item"} 
                            onClick={() => updateInfo(item, index)}>
                            <Link to={arrayLink[index]} 
                                className='selectPage-link' >
                                <span key={index} 
                                    className="selectPage-svg">
                                        {arraySvg[index]}
                                </span>
                                {item}
                            </Link>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default DDselectPage;