import React, { useEffect, useState } from "react"
import { ReactElement } from "react"
import './Alert.css';


interface AlertProps {
    typeAlert: 'successAl' | 'warningAl' | 'dangerAl' | 'systemAl',
    delay: number,
    children: ReactElement,
    isActive: boolean,
    closeFunc: Function
}

export default function AlertCustom({typeAlert, delay, children, isActive, closeFunc}: AlertProps) {
    const [intervalAlert, setIntervalAlert] = useState(0);
    
    useEffect(() => {
        let count = 0;
        if (isActive) {
            const intervalAl = setInterval(() => {        
                if (count === delay) {
                    count = 0
                    clearInterval(intervalAl)
                    setIntervalAlert(0)
                    closeFunc()
                } else {
                    count = count + 1000
                    setIntervalAlert(count)
                }            
            }, 1000)
            return () => clearInterval(intervalAl)
        }
    }, [isActive, delay, closeFunc])

    return (
        <>
            {
                isActive &&
                    <div className={`alert_container ${typeAlert}`}>
                        {children}
                        <div className={`alert_countdown ${typeAlert}`} style={{width: `${(intervalAlert / delay) * 100}%`}}/>
                    </div>
            } 
        </>
    )
}