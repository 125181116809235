import request from "../../Requests/requests";
import { loadArrayTagsToTask } from "../../store/TagsToTasksStore/TagsToTasksReducer";

export const getTagsToTask = (payload) => {
    return async dispatch => {
        await request.get('/Task/Tags', { params: {
            TaskId: payload.id,
            UserId: localStorage.getItem('token')
        }})
        .then(response => response.data)
        .then(data => dispatch(loadArrayTagsToTask(data)))
        }
    }