import React from "react";
import MainTagList from "../DropDownMenu/SelectTags/MainTagsList";
import { addTags } from "../asyncActions/addTag";
import { useDispatch } from "react-redux";
import {ReactComponent as AddPlus} from "../icons/svg/TagsDDSelect/add-plus.svg"


export default function Finder ({arr, ...props}) {
    const dispatch = useDispatch()
    const patternReg = props.matchRegExp;
    const escapedPattern = patternReg.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const matched = new RegExp (escapedPattern, 'i').test(props.tags.map((tags) => tags.tagName))
    // const matched = new RegExp (props.matchRegExp, 'gi').test(props.tags.map((tags) => tags.tagName))

    const addTagFunction = (value) => {
        dispatch(addTags({
            tagName: value
        }))
    }
    if (matched) {
        return (
            // eslint-disable-next-line
            props.tags.map((tags, index) => {
                if (new RegExp (escapedPattern, 'i').test(tags.tagName)) {
                        return (
                            <MainTagList modalShow={props.modalShow}
                            updateTagList={props.updateTagList} 
                            key={index}
                            inList={arr?.includes(tags.id)}
                            matchRegExp={props.matchRegExp}
                            removeTagFromTagList={props.removeTagFromTagList}
                            taskId={props.taskId} 
                            tags={tags}/>
                    )
                }
            })
        )
    } else {
        return (
            <div className="notmatch-container">
                <button className="addTagFromFinder" onClick={() => addTagFunction(props.matchRegExp)}>
                    <AddPlus className="addPlus"></AddPlus>
                    Создать метку
                </button>
            </div>
        )
    }
}