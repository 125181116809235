import request from "../Requests/requests";
import { addTodoRemoved, loadRemovedTodo } from "../store/removedTodosReducer";

export const getRemoveTodos = (dataLoad, setHasNextPage) => {
    return async dispatch => {
        await request.get('/Task/Deleted/', {params: {
            UserId: localStorage.getItem('token'),
            pageSize: dataLoad.pageSize,
            pageIndex: dataLoad.pageIndex 
        }})
        .then(response => response.data)
        .then(data => dataLoad.pageIndex === 1 ? dispatch(loadRemovedTodo(data.tasks)) && setHasNextPage(data.hasNextPage) :  dispatch(addTodoRemoved(data.tasks)) && setHasNextPage(data.hasNextPage)
            )
        }
    }