import React from "react";

export default function Loader() {
  return (
    <div className="loader-container">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        </div>
      </div>
    )
}
