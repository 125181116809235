import React from "react";
import { ReactComponent as SwipeComplete} from "./SwipeableIcons/swipe-comlete-task.svg"
import { completeTodo } from "../asyncActions/comleteTodos";
import { notCompleteTodo } from "../asyncActions/notCompletedTodo";
import { restoreTodo } from "../asyncActions/restoreTodos";
import { useDispatch } from 'react-redux/es/exports';

export default function SwipeToRightFromTask({data, todoId, updateIsSwipeRight, uncompleteBtn, restoreBtn}) {
    const dispatch = useDispatch();
    return (
        <div onClick={(e) => data.update(false) & e.stopPropagation()} 
            className="wrapper-swiped-right" 
            style={data.swipe ? {display:"flex", 
                                    width:`${data.swipe + 7}px`,
                                    maxWidth: '100%', 
                                    height: `${data.dynamicHeight(todoId)}px`, 
                                    background: `${uncompleteBtn || restoreBtn ? 
                                        'linear-gradient(90deg, #F8C440 2.63%, #5932EA 138.95%)' :
                                        'linear-gradient(90deg, #32EAA8 2.63%, #5932EA 138.95%)'}`} : 
                                    {display: "none"}}>
            <div className="wrapper-swiped-right__content" 
                onClick={(e) => updateIsSwipeRight(0) & 
                uncompleteBtn ? dispatch(notCompleteTodo(todoId)) : 
                    restoreBtn ? dispatch(restoreTodo(todoId)) : dispatch(completeTodo(todoId)) &
                data.stopTimer() & 
                e.stopPropagation()}>
                <SwipeComplete />
                <p className="wrapper-swiped-right__description" 
                    style={data.swipe < 200 ? 
                    {display:'none'} : 
                    {animationName: 'fadeIn', 
                    animationDuration: '0.8s', 
                    animationFillMode: 'both'}}>
                        {uncompleteBtn || restoreBtn? 'Отпустите для восстановления' : 'Отпустите для выполнения'}
                       
                </p>
            </div>
        </div>
    )
}