import React from "react";
import './AddTaskButtons.css';
import { ReactComponent as CalendarIcon } from "./static/calendar-item.svg"

const AddTaskButtons = ({ isActiveToday, 
        isActiveTomorrow, 
        setDateToday, 
        setDateTomorrow, 
        setIsActiveToday, 
        setIsActiveTomorrow, 
        setNoDate, 
        setIsActiveNoData,
        disabledItem, 
        isActiveNoData }) => {

    
    
    return (
        <li  className='calendar__item calendar__item-indent' style={{cursor: "default"}}>
            <ul className='calendar__get-date-from-btns' style={disabledItem ? {justifyContent: "flex-start", gap: '20px'} : null}>
                <li style={{cursor: "default"}}>
                    <CalendarIcon className="calendarIcon"></CalendarIcon>
                </li>
                <li style={disabledItem === 'today' ? {display:"none"} : null}>
                    <span>
                        <p className={ isActiveToday ? 
                                'get-today-date active-get-date' : 
                                'get-today-date'} 
                            onClick={() => setDateToday() 
                                        }> 
                            Сегодня
                        </p>
                    </span>
                </li>
                <div className='calendar__separator' style={disabledItem === 'today' ? {display:"none"} : null}></div>
                <li style={disabledItem === 'tomorrow' ? {display:"none"} : null}>
                    <span>
                        <p className={isActiveTomorrow ? 
                                'get-tomorrow-date active-get-date' : 
                                'get-tomorrow-date'} 
                            onClick={() => setDateTomorrow() 
                                       }>
                            Завтра
                        </p>
                    </span>
                </li>
                <div className='calendar__separator' style={disabledItem === 'noDate' ? {display:"none"} : null}></div>
                <li style={disabledItem === 'noDate' ? {display:"none"} : null}>
                    <span>
                        <p className={isActiveNoData ? 
                                'get-nodata-date active-get-date' : 
                                'get-nodata-date'} 
                            onClick={() => setNoDate() 
                                        }>
                            Без срока
                        </p>
                    </span>
                </li>
            </ul>
        </li>
    )
}

export default AddTaskButtons;