const defaultState = {
    elapsedTimeToTodos: [],
}

const LOAD_DATA_SPENT_TIME = "LOAD_DATA_SPENT_TIME";

export const elapsedTimeToTodosReducer = (state = defaultState, action) => {
    switch(action.type) {
      case LOAD_DATA_SPENT_TIME:
        return {...state, elapsedTimeToTodos: [action.payload]}
      default:
        return state
    }
  }

export const loadDataElapsedTime = (payload) => ({type: LOAD_DATA_SPENT_TIME, payload})