import React from "react";
import SubMenu from "../../Submenu/SubmenuApp";
import TodosTodayApp from "../../Todos/TodosTodayApp";
// import TaskSelection from "../GroupTaskEditing/TaskSelection";
import Dashboard from "../../Dashboard/Dashboard";


export default function TodayTask({setIcon}) {



    // const clearSelect = event => {
    //     if(event.ctrlKey){
    //         return;
    //     }else{
    //         TaskSelection("clear");
    //         return;
    //     }
    // }
    return (
        <>  
            <div className="main-container-view">
                <div className="submenu__container">
                    <SubMenu></SubMenu>
                </div>
                {

                }
                <div className="page-container">
                    <div className="page-info">
                        <Dashboard></Dashboard>
                    </div>
                    <div>
                        <TodosTodayApp setIcon={setIcon}></TodosTodayApp>
                    </div>
                </div>
            </div>
        </>
    )
}
