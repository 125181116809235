import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { getTodosCompleted } from "../asyncActions/getTodosCompleted";
import { loadRunTimeResults } from "../store/RunTimeTaskResults/RunTimeResults";
// import GroupChangeMenu from "../GroupTaskEditing/GroupChangeMenu";
import { ReactComponent as ModalTitleImg } from "../icons/svg/reset-pswd-accept.svg";
import TodoList from "./TodosList";
import Loader from "../Loader/Lodaer";
import { addOnContextState } from "../store/contextMenuTaskReducer";
import PaginationBtn from "../UIcomponents/PaginationBtn/PaginationBtn";


export default function TodoListCompleted({setIcon})  {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const todosCompleted = useSelector(state => state.todosCompleted.todosCompleted);
    const [dataLoad, setDataLoad] = useState({pageSize: 10, pageIndex: 1});
    const [hasNextPage, setHasNextPage] = useState(true);
    const paginationBtnData = {
        title: 'Показать еще'
    }
    useEffect(() => {
        const resetPageIndex = () => {
            setDataLoad({...dataLoad, pageIndex: 1})
        }

        window.addEventListener('beforeunload', resetPageIndex)

        return () => {
            window.removeEventListener('beforeunload', resetPageIndex)
        }
    }, [dataLoad])

    useEffect(() => {
        dispatch(getTodosCompleted(dataLoad, setHasNextPage));
        setLoading(false);
        setIcon(<ModalTitleImg style={{width: '14px'}}/>)
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [getTodosCompleted, dataLoad])

    return (
            <div className="todo-list">
                <div className="todo-list__container">
                    {loading && <Loader></Loader>}
                    <div id='todosMenu' className="task-actions-container to_the_end">
                        {/* <GroupChangeMenu />                         */}
                    </div>
                    <ul className="todo-list__list">
                        {todosCompleted.length ? (<div>
                            {todosCompleted.map((todos, index) => {
                                dispatch(loadRunTimeResults({isValue: false, taskId: todos.id}))
                                dispatch(addOnContextState({contextIsOpen: false, contextTaskId: todos.id}))
                                return <TodoList 
                                            uncompleteBtn
                                            disableContextMenu 
                                            timerDisplay 
                                            todos={todos}
                                            disabled
                                            fromCompleted
                                            dyblicateTaskToday 
                                            key={index} />
                            }) }
                            {
                                hasNextPage ?
                                <div className="page_pagination">
                                    <PaginationBtn paginationBtnData={paginationBtnData} startIndex={dataLoad} handleClick={setDataLoad}></PaginationBtn>
                                </div>
                                : null
                            }
                            {/* <button onClick={() => setDataLoad({...dataLoad, pageIndex: dataLoad.pageIndex + 1})}>Показать еще</button> */}
                            </div>) : loading ? null : (<p className="todo-list__is-null">Вы пока не выполнили ни одной задачи</p>)}
                    </ul>
                </div>
            </div>
    )
    }