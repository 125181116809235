import React from "react";
import './CustomInputSelect.css'


const CustomInputSelect = ({logo, title, stylesSelect, stylesLogo, stylesTitle, onClickFunc, children}) => {

    return (
        <span style={stylesSelect} className= "stats-period-select" onClick={onClickFunc}>
            <p style={stylesTitle}>{title}</p>
            <span style={stylesLogo}>{logo}</span>
            {children}
        </span>
        // <button className="button" style={stylesButton} onClick={onClickFunc} disabled={disabled}>
        //     {loading ? 
        //         <div className="centered-btn-spinner">
        //             <div class="btn-lds-ring">
        //                 <div style={{borderColor: loaderColor ? loaderColor : '#fff transparent transparent transparent'}}></div>
        //                 <div style={{borderColor: loaderColor ? loaderColor : '#fff transparent transparent transparent'}}></div>
        //                 <div style={{borderColor: loaderColor ? loaderColor : '#fff transparent transparent transparent'}}></div>
        //                 <div style={{borderColor: loaderColor ? loaderColor : '#fff transparent transparent transparent'}}></div>
        //             </div>
        //         </div> :
        //         <span className="button__container" style={stylesContainer}>
        //             <span className="button__logo" style={stylesLogo}>{logo}</span>
        //             <p className="button__title" style={stylesTitle}>{title}</p>
        //         </span>
        //     }   
        // </button>
    )

}

export default CustomInputSelect;