import { useDispatch, useSelector } from "react-redux";
import { getCheckList } from "../asyncActions/getCheckList";
import { CheckListCompleted } from "../asyncActions/CheckListCompleted";
import { CheckListNotCompleted } from "../asyncActions/CheckListNotCompleted";
import { useEffect, useState } from "react";
import CheckListContent from "./CheckListCotent";





export default function CheckList(props) {
    const dispatch = useDispatch();
    const checkList = useSelector(state => state.checkList.checkList);
    const [countCompleted, setCountCompleted] = useState([]);

    useEffect(() => {
        dispatch(getCheckList(props.taskId));
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [getCheckList]);

    useEffect(() => {
        if (checkList.length) {
            setCountCompleted(checkList.filter((i) => i.isCompleted === true))
        }
    }, [checkList])
    if (checkList === undefined) {
        console.log("Loading data");
    } else {
        return (
            <div>
                <ul className='checkList-container'>
                    <div style={{display: checkList.length > 0 ? "flex" : "none", alignItems: "center", margin: window.screen.width > 576 ? '5px' : '10px'}}>
                        <div className="checkList-container__percent" style={{width: window.screen.width > 576 ? '40%' : '100%', backgroundColor: '#E1E4ED'}}>
                            <div className="runTime-container__percentTime-complete" 
                                style={{width: (countCompleted.length * 100) / checkList.length + '%', 
                                backgroundColor: '#A4A8B1'}}/>
                        </div>
                        <p style={{display: "none", fontSize: '12px', marginLeft: '5px', color: '#232360', fontWeight: 600}}> {countCompleted.length} / {checkList.length}</p>
                    </div>
                    {checkList.length ? (<div>
                        {checkList.map((checklist, index) => {
                            return (
                                <div key={checklist.checklistId} className="checkList">
                                    <label className="label_to_checkBox">
                                        <input
                                            type="checkbox"
                                            id={checklist.checklistId}
                                            className="checkList_checkbox"
                                            checked={checkList[index].isCompleted ? true : false}
                                            onChange={() => {checklist.isCompleted ? dispatch(CheckListNotCompleted(checklist)) : dispatch(CheckListCompleted(checklist)) }}
                                        />
                                        <span></span>
                                    </label>
                                    <CheckListContent checklist={checklist} />
                                </div>
                            )
                        })}
                    </div>) : null}
                </ul>
            </div>
        )
    }

}