import request from "../Requests/requests";
import { addedumCheckList } from "../store/chekListReducer";
import { getCheckList } from "./getCheckList";

export const addCheckList = (payload) => {
    if(payload.checkListTitle === ""){
        return;
    }else{
        return async dispatch => {
            await request.post('/Checklist', { tiskId: payload.taskId, 
            title: payload.checkListTitle,
            });
            dispatch(addedumCheckList(payload));
            dispatch(getCheckList(payload.taskId));
        } 
    }
    
}   