import React, { useEffect, useState } from "react"
import './PriorityView.css'

export interface PriorityViewProps {
  id: number;
}

function PriorityView({ id }: PriorityViewProps) {
  const [priority, setPriority] = useState({
    id: 0,
    name: 'Без приоритета',
    class: 'priority-0'
  });

  const priorityArray = [{ name: 'Без приоритета', id: 3, class: 'priority-0' },
  { name: 'Высокий', id: 0, class: 'priority-1' },
  { name: 'Средний', id: 1, class: 'priority-2' },
  { name: 'Низкий', id: 2, class: 'priority-3' }]

  useEffect(() => {
    setPriority(priorityArray.filter(x => x.id === id)[0]);
    // eslint-disable-next-line
  }, []);

  return (
    <div className='priority_box'>
      <div className={priority.class}></div>
      <p className='priority_decription'>{priority.name}</p>
    </div>
  );
}

export default PriorityView;

