import request from "../../Requests/requests";
import { loadTopForecastData } from "../../store/StatisticsStore/TopForecastReducer";

export const getTopForecast = (payload) => {
    return async dispatch => {
        await request.get('/Statistics/TopForecasts/', {
            params: {UserId: localStorage.getItem('token'),
            DateStart: payload.DateStart, 
            DateEnd: payload.DateEnd }
        })
        .then(response =>{
            const data = response.data;
            dispatch(loadTopForecastData(data))
        })
        
    }
}
