const defaultState = {
    topTags: [],
}

const GET_TOP_TAGS = "GET_TOP_TAGS";

export const topTagsReducer = (state = defaultState, action) => {
    switch(action.type) {
        case GET_TOP_TAGS:
            return {...state, topTags: [action.payload]};
        default:
            return state;
    }
}

export const loadTopTags = (payload) => ({type: GET_TOP_TAGS, payload});