const defaultState = {
  tags: [],
}

const LOAD_DATA_TAG = "LOAD_DATA_TAG"
const ADD_TAG = "ADD_TAG"
const REMOVE_TAG = "REMOVE_TAG"
const EDIT_TAG = "EDIT_TAG"

export const dataTagReducer = (state = defaultState, action) => {
    switch(action.type) {
      case LOAD_DATA_TAG:
        return {...state, tags: [...action.payload]}
      case ADD_TAG:
        return {...state, tags: [...state.tags, action.payload]}
      case REMOVE_TAG:
        return {...state, tags: state.tags.filter(tags => tags.id !== action.payload)}
      case EDIT_TAG:
        const indexEdit = state.tags.findIndex((el) => el.id === action.payload.id);
        return { ...state, tags: [...state.tags.slice(0, indexEdit), action.payload, ...state.tags.slice(indexEdit + 1)] }
      default:
        return state
    }
  }

export const loadDataTag = (payload) => ({type: LOAD_DATA_TAG, payload})
export const addTag = (payload) => ({type: ADD_TAG, payload})
export const removeTag = (payload) => ({type: REMOVE_TAG, payload})
export const editTag = (payload) => ({type: EDIT_TAG, payload})