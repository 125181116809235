import request from "../Requests/requests";
import { removeAllChekList } from "../store/chekListReducer";
import { getCheckList } from "./getCheckList";

export const removAllCheckList = (payload) => {
    return async dispatch => {
        await request.delete('/Checklist/DeleteSubtasks?TaskId=' + payload);
        dispatch(removeAllChekList(payload));
        dispatch(getCheckList(payload))
    }
}