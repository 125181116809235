const defaultState = {
    checkList: [],
}

const LOAD_DATA_CHECKLIST = "LOAD_DATA_CHECKLIST";
const ADD_CHECKLIST = "ADD_CHECKLIST";
const REMOVE_CHECKLIST = "REMOVE_CHECKLIST";
const REMOVE_ALL_CHECKLIST = "REMOVE_ALL_CHECKLIST";
const EDIT_CHEKLIST = "EDIT_CHECKLIST";
const ALL_COMPLETED = "ALL_COMPLETE";
const COMPLETED_CHECKLIST = "COMPLETED_ CHECKLIST";
const NOTCOMPLETED_CHECKLIST = "NOTCOMPLETED_CHEKLIST";

export const checkListReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOAD_DATA_CHECKLIST:
            return { ...state, checkList: [...action.payload] };
        case ADD_CHECKLIST:
            return { ...state, checkList: [...state.checkList, action.payload] };
        case REMOVE_CHECKLIST:
            return { ...state, checkList: state.checkList.filter(checkList => checkList.checklistId !== action.payload) };
        case REMOVE_ALL_CHECKLIST:
            return { ...state, checkLsit: state.checkList.filter(checkList => checkList.taskId !== action.payload.taskId)};
        case EDIT_CHEKLIST:
            const indexEdit = state.checkList.findIndex((el) => el.checklistId === action.payload.checklistId);
            return { ...state, checkList: [...state.checkList.slice(0, indexEdit), action.payload, ...state.checkList.slice(indexEdit + 1)] };
        case ALL_COMPLETED:
            return { ...state, checkLsit: [...state.checkList, action.payload] }
        case COMPLETED_CHECKLIST:
            const index = state.checkList.findIndex((el) => el.checklistId === action.payload.checklistId);
            return { ...state, checkList: [...state.checkList.slice(0, index), action.payload, ...state.checkList.slice(index + 1)] };
        case NOTCOMPLETED_CHECKLIST:
            const indexNo = state.checkList.findIndex((el) => el.checklistId === action.payload.checklistId);
            return { ...state, checkList: [...state.checkList.slice(0, indexNo), action.payload, ...state.checkList.slice(indexNo + 1)] };
        default:
            return state;
    }
};

export const loadDataCheckList = (payload) => ({ type: LOAD_DATA_CHECKLIST, payload });
export const addedumCheckList = (payload) => ({ type: ADD_CHECKLIST, payload });
export const removeCheckList = (payload) => ({ type: REMOVE_CHECKLIST, payload });
export const removeAllChekList = (payload) => ({ type: REMOVE_ALL_CHECKLIST, payload });
export const editCheckList = (payload) => ({ type: EDIT_CHEKLIST, payload });
export const allCompleted = (payload) => ({ type: ALL_COMPLETED, payload });
export const completedCheckList = (payload) => ({ type: COMPLETED_CHECKLIST, payload })
export const notCompletedCheckList = (payload) => ({ type: NOTCOMPLETED_CHECKLIST, payload })
