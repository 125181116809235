const SET_MESSAGE = "SET_MESSAGE";
const SET_COUNT = "SET_COUNT";

let initialState = {
    title: "",
    message: "",
    count: 0
}

const notificationReduser = (state = initialState, action) => {
    switch(action.type){
        case SET_MESSAGE:
            return {
                ...state,
                title: action.title,
                message: action.message,
                count: action.count
            }
        case SET_COUNT:
            return {
                ...state,
                count: 0
            }
        default:
            return state;
    }
}

export const setCount = () => {
    return {
        type: SET_COUNT
    };
}

export const setMessage = (title, message, count) => {
    return {
        type: SET_MESSAGE,
        title: title,
        message: message,
        count: count
    };
}

export default notificationReduser;