import request from "../Requests/requests";
import { removeTodoCompleted } from "../store/completedTodosReducer";
import { getTimeAllTodos } from '../asyncActions/getTimeAllTodos';
import { getCountTime } from "../asyncActions/StatisticsActions/getCountTime";
import { getTodosCompleted } from "./getTodosCompleted";

export const notCompleteTodo = (payload) => {
    const dataLoad = {pageSize: 10, pageIndex: 1};

    return async dispatch => {
        await request.put('/Task/NotCompleted?Id=' + payload);
        dispatch(removeTodoCompleted(payload));
        setTimeout(() => {
            dispatch(getTimeAllTodos());
            dispatch(getCountTime())
            dispatch(getTodosCompleted(dataLoad))
        }, 500);
    } }