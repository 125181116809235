import request from "../Requests/requests";
import { notCompletedCheckList } from "../store/chekListReducer";
import { getCheckList } from "./getCheckList";

export const CheckListNotCompleted = (payload) => {
        return async dispatch => {
            await request.put('/Checklist/NotCompleted?Id=' + payload.checklistId)
            dispatch(notCompletedCheckList(payload))
            dispatch(getCheckList (payload.taskId))
        }
    
}