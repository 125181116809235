import React, {useEffect, useState} from "react";
import { addTagsToTask } from "../../asyncActions/TagsToTaskActions/addTagsToTask";
import { useDispatch } from 'react-redux/es/exports';

export default function MainTagList({updateTagList, taskId, tags, tagList, removeTagFromTagList, inList, ...props}) {
    const dispatch = useDispatch();

    const [checkedValue, setCheckedValue] = useState(false);
    

    const objTagsToTask = {
        taskId: taskId,
        tagId: tags.id
    }
    
    useEffect(() => {
        if(inList) {
            setCheckedValue(true)
        } else {
            setCheckedValue(false)
        }
    }, [inList])

    
    function addTagToTask() {
        dispatch(addTagsToTask(objTagsToTask))
    }
    
    function addTagsToAddTaskFunction(e) {
        updateTagList({
            tagId: tags.id,
            tagName: tags.tagName
        })
        setCheckedValue(true)
        e.preventDefault()
    }

    const isUnchecked = (e) => {
        removeTagFromTagList(tags.id)
        setCheckedValue(false)
        e.preventDefault()
    }
    
    return (
        <>
            <li className="tags__item" onClick={(e) => checkedValue ? isUnchecked(e) : addTagsToAddTaskFunction(e)}>
                    <div className="tags-box">
                        <p className="tags__title">{tags.tagName}</p>
                        <label style={{display: "flex"}}>
                        <input type="checkbox"  className="tags__checkbox" checked={checkedValue} 
                            onChange={() => addTagToTask()} onClick={(e) => checkedValue ? isUnchecked() : addTagsToAddTaskFunction()}/>
                            <span></span>
                        </label>
                    </div>
            </li>
        </>
    )
}