import request from "../Requests/requests";
// import { removeFromArrayTagsToTask } from "../store/TagsToTasksStore/TagsToTasksReducer";
// import { getTagsToTask } from "./TagsToTaskActions/getTagsToTask";
import { getTags } from "./getTag";


export const removeTagsToList = (payload) => {
    return async dispatch => {
        await request.delete('/Tag?Id=' + payload);
        // await request.delete('/Tag/' + payload.taskId + '&TagId=' + payload.tagId);
        // dispatch(removeFromArrayTagsToTask(payload.tagId));
        // dispatch(getTagsToTask(payload.taskId));
        dispatch(getTags());
        }
    }