import React, {useState, useContext, useEffect} from "react";
import { modalWindowContext } from "../Context/modalWindowContext";
import Modal from 'react-bootstrap/Modal';
import request from "../Requests/requests";

export default function ChangeEmailPage(props) {
    const [modalShow, setModalShow] = useContext(modalWindowContext);
    const [email, setEmail] = useState('');
    const [repeatEmail, setRepeatEmail] = useState('');
    const [controlPassword, setControlPassword] = useState('');
    const [errorEmail, setErrorEmail] = useState('hide');
    const [errorRepeatEmail, setErrorRepeatEmail] = useState('hide');
    const [saveBtn, setSaveBtn] = useState('true');
    const [enteredEmail, setEnteredEmail] = useState('true');
    const [errorSavingChanges, setErrorSavingChanges] = useState('');
    const [loading, setLoading] = useState(false);
    /* eslint-disable-next-line */
    let emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
    
    /* eslint-disable-next-line */
    useEffect(() => {
        if ((email === repeatEmail) && (controlPassword.length >= 8) && (email !== '') && (repeatEmail !== '')) {
            setSaveBtn(false)
        } else {
            setSaveBtn(true)
            if ((repeatEmail !== email) && (repeatEmail !== '')) {
                setErrorEmail('error-change-email unhide-error-email');
            } else {
                setErrorEmail('hide');
            }
        }
      });

    function checkRepeatEmail(email, e) {
        if ((email !== e.target.value) && (e.target.value !== '')) {
            setErrorEmail('error-change-email unhide-error-email');
            setSaveBtn(true)
        } else {
            setErrorEmail('hide');
        }
        }

    function checkCurrentEmail(e) {
        if (e.target.value === props.userEmail) {
            setErrorRepeatEmail('error-change-email unhide-error-email');
        } else {
            setErrorRepeatEmail(' hide')
        }
        
        if (emailReg.test(e.target.value) === true) {
            setEnteredEmail(false)
        } else if (emailReg.test(e.target.value) === false) {
            setEnteredEmail(true)
        }
    }

    function submitChanges(e) {
        if((e.keyCode === 13) && (saveBtn === false)) {
            pushChanges(email)
        }
    }

    function pushChanges(email) {
        setLoading(true)
        request.put('/Account/ChangeEmail/', {userId: localStorage.getItem('token'), 
        newEmail: email,
        oldEmail: props.userEmail,
        password:  controlPassword  })
        .then(res => {
            setLoading(false)
            if (res.data.errors) {
                setErrorSavingChanges(res.data.errors)
            } else {
                props.updateDataEmail(email)
                props.isSuccesAlert('success-alert-unhide')
                setModalShow(false)
            }
        })
        // .catch(function (error) {
        //     if(error.response) {
        //         setErrorSavingChanges(error.response.data.errors)
        //         setLoading(false)
        //     }
        // });
    }
    //Проверка перед закрытием окна
    // function checkBeforePushChanges () {                   
    //     if ((email !== '') || (repeatEmail !== '')) {
    //         alert('Сохраните изменения или отмените их!');
    //     } else {
    //         setTimeout(() => setModalShow(false), 100);
    //     } 
    // }

    return (
        <Modal
            {...props}
            show = {modalShow}
            onHide = {() => setModalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >   
            <Modal.Header className='remove__border'>
                <Modal.Title id="contained-modal-title-vcenter">
                    <p className="change-data__title">Изменение Email</p> 
                    <p className="change-data__description">Измените Email-адрес, привязанный к Вашему аккаунту. Текущий Email: <b>{props.userEmail}</b> </p>
                    <div className="border_of_modalWindow" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal_body">
                <div className="change-data__container">
                    <form className="change-data__form" onSubmit={pushChanges} onKeyDown={e => submitChanges(e)}>
                        <div className="change-data__box">
                            <p className="change-data__box__title">Новый Email</p>
                            <input 
                                type="email" 
                                id="new_email" 
                                className="settings-profile__other change-data__input" 
                                value={email} 
                                placeholder='example@ex.com'
                                autoComplete="off"
                                onChange={e => setEmail(e.target.value) & checkCurrentEmail(e)} />
                            <p className={errorRepeatEmail}>* новый Email совпадает с текущим</p>
                            {errorSavingChanges.NewEmail ? <p className='error-change-email unhide-error-email'>* {errorSavingChanges.NewEmail[0]}</p> : null}
                        </div >
                        <div className="change-data__box">
                            <p className="change-data__box__title">Подтвердите Email</p>
                            <input 
                                type="email" 
                                id="confirm_email" 
                                disabled={enteredEmail} 
                                className="settings-profile__other change-data__input" 
                                value={repeatEmail} 
                                placeholder='example@ex.com'
                                autoComplete="off" 
                                onChange={e => setRepeatEmail(e.target.value) & checkRepeatEmail(email, e)} />
                            <div className={errorEmail}>* не совпадают Email адреса</div>
                        </div>
                        <div className="change-data__box">
                            <p className="change-data__box__title">Пароль</p>
                            <input 
                                type="password" 
                                id="confirm_pass"
                                autoComplete="new-password" 
                                className="settings-profile__other change-data__input" 
                                required
                                onChange={(e) => setControlPassword(e.target.value)} />
                            {errorSavingChanges.Password ? <p className='error-change-email unhide-error-email'>* {errorSavingChanges.Password[0]}</p> : null}
                            
                        </div>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="settings-page__btn-group">
                            <button className="settings-page__btn-close" onClick={() => setModalShow(false)}>Отмена</button>
                            <button type='submit' 
                                    className="settings-page__btn-save" 
                                    disabled={saveBtn} onClick={() => pushChanges(email)}>
                                        {loading ? <div className="centered-btn-spinner"><div class="btn-lds-ring"><div></div><div></div><div></div><div></div></div></div> : 'Сохранить'}
                            </button>
                        </div>
            </Modal.Footer>
    </Modal>
    );
}