import React, { useRef, useEffect } from "react";
import './ModalWindow.css';
import {ReactComponent as CloseBtn} from './static/closeModal.svg';
import { useOutsideClick } from "../../customHooks/useOutsideClick";
import { ReactComponent as ModalLogo } from "../../icons/svg/modalLogo.svg"
const ModalWindow = ({title, titleType, headerSize, description, children, logo, buttons, modalShow, updateModalState, disabledCloseBtn, disabledOutsideClose, position}) => {
    const refCloseModal = useRef();
    useOutsideClick(refCloseModal, () => updateModalState(false), modalShow);

    useEffect(() => {
        if (modalShow) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }

        return () => (document.body.style.overflow = "unset");
    }, [modalShow]);

    return (
        <div id="openModal" className={modalShow ? "modalWindow" : 'hide'} style={{alignItems: position}}>
            <div className="modalWindow__container" ref={disabledOutsideClose === true ? null : refCloseModal}>
                    <div className="modalWindow__close" style={disabledCloseBtn ? {display: "none"} : null} onClick={() => updateModalState(false)}>
                    <CloseBtn className="modalWindows_close-btn"/>
                    </div>
                {logo && <div className="modalWindow__logo">
                        <ModalLogo />
                </div>}
                <div className="modalWindow__content">
                    {
                        title && 
                        <div className={`modalWindow__header ${titleType}`}>
                            <h1 className={`modalWindow__title ${titleType}`} style={{fontSize: `${headerSize}px` ?? null}}>{title}</h1>
                        </div>
                    }
                    {
                        description &&
                        <div className="modalWindow__body">    
                            <p className="modalWindow__body-description">
                                {description} 
                            </p>
                        </div>
                    }
                    {children}
                </div>
                { buttons &&
                    <div className="modalWindow__btn-group">
                        {buttons}
                    </div>
                }
            </div>
        </div>
    )
}

export default ModalWindow;