import request from "../../Requests/requests";
import { loadSpentTimeResults } from "../../store/SpentTimeTask/SpentTimeReducer";
import getDateUTC from "../../utils/getDateUTC";

export const getSpentTime = () => {
    return async dispatch => {
        await request.get('/Time/SpentTime', {params: {date: getDateUTC(), userId: localStorage.getItem('token')}})
        .then(response => response.data)
        .then(data => dispatch(loadSpentTimeResults(data)))
        }
    }