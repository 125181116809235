const defaultState = {
    userParams: [],
}

const LOAD_PARAMS = "LOAD_PARAMS"

export const userParamsReducer = (state = defaultState, action) => {
    switch(action.type) {
      case LOAD_PARAMS:
        return {...state, userParams: [action.payload]}
      default:
        return state
    }
  }

export const loadUserParams = (payload) => ({type: LOAD_PARAMS, payload})