import request from "../Requests/requests";
import { addTodoCompleted, loadDataTodoCompleted } from "../store/completedTodosReducer";

export const getTodosCompleted = (dataLoad, setHasNextPage) => {
    return async dispatch => {
        await request.get('/Task/Completed/', {params: {
            userId: localStorage.getItem('token'), 
            pageSize: dataLoad.pageSize, 
            pageIndex: dataLoad.pageIndex
        }})
        .then(response => response.data)
        .then(data =>  dataLoad.pageIndex === 1 ? dispatch(loadDataTodoCompleted(data.tasks)) && setHasNextPage(data.hasNextPage) : dispatch(addTodoCompleted(data.tasks)) && setHasNextPage(data.hasNextPage))
        }
    }