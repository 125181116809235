import request from "../Requests/requests";
import { setAvatarUrl } from "../store/settingsReducer";

export const getAvatar = () => {
    return async dispatch => {
        await request.get(`Account/Avatar?UserId=` + localStorage.getItem('token'))
            .then(data => {
                dispatch(setAvatarUrl(data.data));
            });
    }
}

export const setAvatar = (avatar) => {
    const form = new FormData();
    form.append("image", avatar);
    form.append("userId", localStorage.getItem('token'));

    return async dispatch => {
        await request.post('Account/Avatar', form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(() => {
            dispatch(getAvatar());
        });
    }
}