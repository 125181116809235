import request from "../Requests/requests";
import { restoreTodoAction} from "../store/removedTodosReducer";
import { getTimeAllTodos } from '../asyncActions/getTimeAllTodos';
import { getRemoveTodos } from "./getRemovedTodos";

export const restoreTodo = (payload) => {
    const dataLoad = {pageSize: 10, pageIndex: 1};

    return async dispatch => {
        await request.put('/Task/NotDelete?Id=' + payload);
        dispatch(restoreTodoAction(payload));
        setTimeout(() => {
            dispatch(getTimeAllTodos());
            dispatch(getRemoveTodos(dataLoad))
        }, 500);
        }
    }