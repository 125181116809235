import request from "../../Requests/requests";
import { loadTopTags } from "../../store/StatisticsStore/TopTagsReducer";

export const getTopTags = (payload) => {
    return async dispatch => {
        await request.get('/Statistics/Tags/', {
            params: {UserId: localStorage.getItem('token'),
            DateStart: payload.DateStart, 
            DateEnd: payload.DateEnd,
            StatisticTagsType: payload.StatisticTagsType }
        })
        .then(response =>{
            const data = response.data;
            dispatch(loadTopTags(data))
        })
        
    }
}