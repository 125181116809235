import React from "react";
import './/index.css'

const PieDiagramm = (data) => {
    const arr = [data.data.length !== 0 ? data.data[0].tags.map((tag) => tag.percent) : null]
    return (
        <>
        <div className="flex-pie">
            {
                data.data[0].tags.map((tag, index) => {
                    let pp;
                    switch (index) {
                        case 0:
                            pp = {
                                '--p0': `${arr[0][0]}`,
                                '--percent0': tag.percent
                            };
                            break;
                        case 1:
                            pp = {
                                '--p1': `${arr[0][0] + arr[0][1]}`,
                                '--percent1': tag.percent
                            };
                            break;
                        case 2:
                            pp = {
                                '--p2': `${arr[0][0] + arr[0][1] + arr[0][2]}`,
                                '--percent2': tag.percent
                            };
                            break;
                        case 3:
                            if (arr[0][0] + arr[0][1] + arr[0][2] + arr[0][3] === 99.99) {
                                pp = {
                                    '--p3': `${arr[0][0] + arr[0][1] + arr[0][2] + arr[0][3] + 0.01}`,
                                    '--percent3': tag.percent + 0.01
                                };
                            } else if (arr[0][0] + arr[0][1] + arr[0][2] + arr[0][3] === 99.9) {
                                pp = {
                                    '--p3': `${arr[0][0] + arr[0][1] + arr[0][2] + arr[0][3] + 0.1}`,
                                    '--percent3': tag.percent + 0.1
                                };
                            } else {
                                pp = {
                                    '--p3': `${arr[0][0] + arr[0][1] + arr[0][2] + arr[0][3]}`,
                                    '--percent3': tag.percent
                                };
                            }
                            break;
                        default:
                            break;
                    }
                    return (<div key={index} className={'pie-' + index} style={pp}><div></div></div>)
                })
            }
        </div>
        </>
    )
}

export default PieDiagramm;