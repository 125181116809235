import request from "../Requests/requests";
import { loadDataTag } from "../store/tagsReducer";

export const getTags = () => {
    return async dispatch => {
        await request.get('/Tag/Tags/', { params: {
            UserId: localStorage.getItem('token')}})
        .then(response => response.data)
        .then(data => dispatch(loadDataTag(data)))
    }
    }