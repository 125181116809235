const defaultState = {
    todosCompleted: [],
}

const ADD_TODO_ARCHIVE = "ADD_TODO_ARCHIVE"
const REMOVE_TODO_ARCHIVE = "REMOVE_TODO_ARCHIVE"
const LOAD_DATA_TODO_ARCHIVE = "LOAD_DATA_TODO_ARCHIVE"

export const todosCompletedReducer = (state = defaultState, action) => {
    switch(action.type) {
      case ADD_TODO_ARCHIVE:
        return {...state, todosCompleted: [...state.todosCompleted, ...action.payload]} 
      case REMOVE_TODO_ARCHIVE:
        return {...state, todosCompleted: state.todosCompleted.filter(todosCompleted => todosCompleted.id !== action.payload)}
      case LOAD_DATA_TODO_ARCHIVE:
        return {...state, todosCompleted: [...action.payload]}
      default:
        return state
    }
  }

export const addTodoCompleted = (payload) => ({type: ADD_TODO_ARCHIVE, payload})
export const removeTodoCompleted = (payload) => ({type: REMOVE_TODO_ARCHIVE, payload})
export const loadDataTodoCompleted = (payload) => ({type: LOAD_DATA_TODO_ARCHIVE, payload})