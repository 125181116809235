import React from "react";
import './PaginationBtn.css'

const PaginationBtn = ({paginationBtnData, handleClick, startIndex}) => {
    let i = startIndex.pageIndex
    return(
        <button className="paginationBtn" 
            style={paginationBtnData.styles}
            onClick={() => handleClick({...startIndex, pageIndex: i + 1})}>
            {paginationBtnData.title}
        </button>
    )
}

export default PaginationBtn;