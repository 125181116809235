import React from "react";
import './index.css';
import InpuTimeToTask from "../../UIcomponents/InputTimeToTask/InputTimeToTask";


const DDSelectTimeToTask = (props) => {

    return (
        <div className={props.active ? 'menu active' : 'menu'} onClick={() => props.setActive(false)}>
            <div className='TimeToTask__container' onClick={e => e.stopPropagation()}>
                <div className="TimeToTask__container-description">
                    <div className="TimeToTask__icon" />
                    <p className="TimeToTask__description">Введите время на задачу</p>
                </div>
                <InpuTimeToTask active={props.active}
                    updateDataTimeToTaskHours={props.updateDataTimeToTaskHours}
                    updateDataTimeToTaskMinutes={props.updateDataTimeToTaskMinutes}
                    timeToTaskHours={props.timeToTaskHours}
                    timeToTaskMinutes={props.timeToTaskMinutes} />
          </div>
        </div>
    )
}

export default DDSelectTimeToTask;