import React from "react";
import { NavLink } from "react-router-dom";
// import TimerOutside from "../TaskTimer/TimerOutside";

export default function SubMenu() {
    return (
        <>  
            <nav className="submenu">
                <ul className="submenu__list">
                    <li className="submenu__item">
                        <NavLink to='/' className={({isActive}) => isActive ? 'submenu__link-active submenu__item-active' : 'submenu__link'}>
                            <div className="submenu__item-icon-1"></div>
                            Мой день
                        </NavLink>
                    </li>
                    <li className="submenu__item bg-submenu-2">
                        <NavLink to='/calendar' className={({isActive}) => isActive ? 'submenu__link-active submenu__item-active' : 'submenu__link'}>
                            <div className="submenu__item-icon-2"></div>
                            Календарь
                        </NavLink>
                    </li>
                    <li className="submenu__item bg-submenu-3">
                        <div className="rect-menu"></div>
                        <NavLink to='/viewlist' className={({isActive}) => isActive ? 'submenu__link-active submenu__item-active' : 'submenu__link'}>
                            <div className="submenu__item-icon-3"></div>
                            Входящие задачи
                        </NavLink>
                    </li>
                    {/* <li className="submenu__item">
                        <TimerOutside/>
                    </li> */}
                    
                </ul>
            </nav>  
        </>
    )
}