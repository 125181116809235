import React, { useEffect, useState } from "react";
import "./TagsApp.css";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { getTags } from "../asyncActions/getTag";
import TagsAddFunction from "./TagsAddFunction";
import TagsList from "./TagsList";
import Loader from "../Loader/Lodaer";


export default function TagsApp()  {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const tags = useSelector(state => state.tags.tags);

    useEffect(() => {
        dispatch(getTags());
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        setLoading(false);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [getTags])

    return (
            <div className="tags-page-list">
                <div className="tags-page__container">  
                    {loading && <Loader></Loader>}
                    <ul className="todo-list__list">
                        {tags.length ? (<div>
                            {tags.map((tags, index = 0) => {
                                index++;
                                return <TagsList key={tags.id} tags={tags}></TagsList>
                            }) }
                            </div>) : loading ? null : (<p className="todo-list__is-null">Ваш список меток пуст.</p>)}
                    </ul>
                    <TagsAddFunction></TagsAddFunction>
                </div>
            </div>
    )
    }