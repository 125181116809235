import axios, { AxiosRequestHeaders } from "axios";
import Cookies from 'universal-cookie';

const API_URL = process.env.REACT_APP_API_BASEURL ?? 'https://api-demo.timetask.ru/api/';
//const API_URL = 'https://localhost:5001/api/';
const cookies = new Cookies();

const request = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  // timeout: 1000,
});

request.interceptors.request.use((config) => {
  const authToken = localStorage.getItem('authToken');
  if (authToken) {
    (config.headers as AxiosRequestHeaders).Authorization = `Bearer ${authToken}`;
  }
    return config;
});

request.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  if (error.response && error.response.status === 401) {
    const originalRequest = error.config;
  if (error.response.status === 401 && !error.config._isRetry && localStorage.getItem('interceptorTry') === 'false') {
    localStorage.setItem('interceptorTry', 'true');
    originalRequest._isRetry = true;
    try {
      const response = await axios.post(`${API_URL}Account/refresh`, {
        refreshToken: cookies.get('refreshToken')
      })
      localStorage.setItem('authToken', response.data.token);
      cookies.set('refreshToken', response.data.refreshToken, {maxAge: 24*60*60*7});
      localStorage.setItem('interceptorTry', 'false');
      return request.request(originalRequest);
    } catch (e) {
      console.log(e)
      localStorage.removeItem('token');
      localStorage.removeItem('authToken');
      localStorage.removeItem('liveRefreshUntil')
      cookies.remove('refreshToken');
      localStorage.setItem('interceptorTry', 'false');
      window.location.replace('/login');
    }}
  }
  return Promise.reject(error)
})


export default request;
