const defaultState = {
    onContextState: [{
        contextIsOpen: false,
        contextTaskId: null
    }]
}

const ADD_ONCONTEXT_STATE = "ADD_ONCONTEXT_STATE"
const CHANGE_ONCONTEXT_STATE = "CHANGE_ONCONTEXT_STATE"

export const onContextReduser = (state = defaultState, action) => {
    switch(action.type) {
      case ADD_ONCONTEXT_STATE:
        return {...state, onContextState: [...state.onContextState, action.payload]} 
      case CHANGE_ONCONTEXT_STATE:
        return {...state, onContextState: state.onContextState.map(onContextState => onContextState.contextTaskId === action.payload.contextTaskId ? action.payload : {contextIsOpen: false, contextTaskId: onContextState.contextTaskId})};
      default:
        return state
    }
  }

export const addOnContextState = (payload) => ({type: ADD_ONCONTEXT_STATE, payload})
export const changeOnContextState = (payload) => ({type: CHANGE_ONCONTEXT_STATE, payload})
