
const defaultState = {
    stopWatchesData: [{
        isTimerActive: false,
        taskId: null,
        startTime: null
    }],
}

const IS_TIMER_COLLAPSED_RESULTS = "IS_TIMER_COLLAPSED_RESULTS";
const ACTIVATE_TIMER = "ACTIVATE_TIMER";
const REMOVE_TIMER = "REMOVE_TIMER";

export const stopWatchesDataReducer = (state = defaultState, action: { type: any; payload: { taskId: number, isTimerActive: boolean, startTime: number } }) => {
    switch(action.type) {
        case IS_TIMER_COLLAPSED_RESULTS:
            return {...state, stopWatchesData: [...state.stopWatchesData, action.payload].filter((elem: any, index: any, self: any) => {return index === self.findIndex((t: any) => (t.taskId === elem.taskId))})};
        case ACTIVATE_TIMER:
            if (action.payload.isTimerActive) {
                return {...state, stopWatchesData: state.stopWatchesData.map(stopWatchesData => stopWatchesData.taskId === action.payload.taskId ? action.payload : {isTimerActive: false, taskId: stopWatchesData.taskId, startTime: stopWatchesData.startTime})};
            } else {
                return {...state, stopWatchesData: state.stopWatchesData.map(stopWatchesData => stopWatchesData.taskId === action.payload.taskId ? action.payload : {isTimerActive: stopWatchesData.isTimerActive, taskId: stopWatchesData.taskId, startTime: stopWatchesData.startTime})}
            }
        case REMOVE_TIMER:
            let deletetItemIndex = (state as any).stopWatchesData.indexOf(action.payload.taskId)
            return {...state, stopWatchesData: state.stopWatchesData.splice(deletetItemIndex, 1)}
        default:
            return state;
    }
}

export const loadRunTimeResults = (payload: { isTimerActive: boolean; taskId: any; startTime?: any }) => ({type: IS_TIMER_COLLAPSED_RESULTS, payload});
export const activateTimer = (payload: { isTimerActive: any; taskId: any; startTime?: any }) => ({type: ACTIVATE_TIMER, payload});
export const removeTimer = (payload: any) => ({type: REMOVE_TIMER, payload});

