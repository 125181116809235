import {React} from "react";
import TodoListRemoved from "../../Todos/TodosDeletedApp";
import SubMenu from "../../Submenu/SubmenuApp";
import { useDispatch } from 'react-redux/es/exports';
import { getClearCart } from '../../asyncActions/clearCart';
import './DeletedTodosPage.css';
import {ReactComponent as DeletedIcon} from '../../../src/icons/svg/Deleted-icon.svg';

export default function DeletedTodosPage({setIcon}) {
    const dispatch = useDispatch();
    
    return (
        <div className="main-container-view">
                <div className="submenu__container">
                    <SubMenu></SubMenu>
                </div>
                <div className="page-container">
                    <div className="page-info deleted-page">
                        <div style={{alignItems: 'center'}} className="d-flex flex-row">
                            <DeletedIcon className="page-info__deleted-icon"/>
                            <h1 className="page-title">Корзина</h1>
                        </div>
                        
                        <button className="empty-the-trash" 
                            onClick={() => dispatch(getClearCart(0))}>
                                Очистить корзину
                        </button>
                    </div>
                    <div>
                        <TodoListRemoved setIcon={setIcon}></TodoListRemoved>
                    </div>
                </div>
            </div>
    )
}
