import request from "../Requests/requests";
import { getTodos } from "./getTodosToday";
import { getTimeAllTodos } from '../asyncActions/getTimeAllTodos';
import { getTodosIncoming } from "./getIncomingTodos";
import { getCountTime } from "../asyncActions/StatisticsActions/getCountTime";
import { getCalendarTodos } from "./CalendarService/getCalendarTodos";

export const addTodo = (payload) => {
    const date = new Date();
    const dateNormalize = date.toLocaleDateString();
    const dataLoad = {pageSize: 10, pageIndex: 1};
    var splitingDate = payload.date
    const [day, month, year] = splitingDate.split('.');
    const calendarDate = new Date(`${year}-${month}-${day}`)
    return async dispatch => {
        await request.post('/Task', { userId: localStorage.getItem('token'), 
        title: payload.title,
        description: payload.description,
        date: payload.date,
        time: payload.time ? payload.time : null,
        priority: payload.priority,
        hours: payload.hours,
        minutes: payload.minutes,
        tagsId: payload.tagsId ? payload.tagsId : null })
        if (payload.date === dateNormalize) {
                    dispatch(getTodos());
                    dispatch(getTodosIncoming(dataLoad));
                    dispatch(getCalendarTodos(date.toDateString()));
                    setTimeout(() => {
                        dispatch(getTimeAllTodos());
                        dispatch(getCountTime());
                    }, 500);
                } else {
                    dispatch(getTodosIncoming(dataLoad));
                    setTimeout(() => {
                        if (date.toLocaleDateString() === calendarDate.toLocaleDateString()) {
                            dispatch(getCalendarTodos(date.toDateString()));
                        } else {
                            dispatch(getCalendarTodos(payload.dateFrom))
                        }
                        dispatch(getTimeAllTodos());
                        dispatch(getCountTime());
                    }, 500);
                }}
    }
       