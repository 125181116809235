const defaultState = {
    todosIncoming: [],
}

const LOAD_DATA_TODO_INCOMING = "LOAD_DATA_TODO_INCOMING"
const ADD_TODO_INCOMING = "ADD_TODO_INCOMING"
const REMOVE_TODO_INCOMING = "REMOVE_TODO_INCOMING"
const EDIT_TODO_INCOMING = "EDIT_TODO_INCOMING"

export const dataTodoIncomingReducer = (state = defaultState, action) => {
    switch(action.type) {
      case LOAD_DATA_TODO_INCOMING:
        return {...state, todosIncoming: [...action.payload]}
      case ADD_TODO_INCOMING:
        return {...state, todosIncoming: [...state.todosIncoming, ...action.payload]}
      case REMOVE_TODO_INCOMING:
        return {...state, todosIncoming: state.todosIncoming.filter(todosIncoming => todosIncoming.id !== action.payload)}
      case EDIT_TODO_INCOMING:
        return {...state, todosIncoming: [...state.todosIncoming, action.payload]}
      default:
        return state
    }
  }

export const loadDataTodoIncoming = (payload) => ({type: LOAD_DATA_TODO_INCOMING, payload})
export const addTodoIncoming = (payload) => ({type: ADD_TODO_INCOMING, payload})
export const removeTodoIncoming = (payload) => ({type: REMOVE_TODO_INCOMING, payload})
export const editTodoIncoming = (payload) => ({type: EDIT_TODO_INCOMING, payload})