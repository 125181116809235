import './index.css';
import { useState } from 'react';

const DDSelectFilter = (props) => {
    const [sortMethod, setSortMethod] = useState(Number(sessionStorage.getItem('key_FilterMethod')));
    const [sortOrder, setSortOrder] = useState(props.sortOrderRef);
    
    function selectSortMethod (selectedSortMethod, selectedSortOrder) {
        if((selectedSortMethod === 6) || (selectedSortMethod === 5)){            
            props.updateSortMethod({
                sortMethod: selectedSortMethod,
                sortOrder: null
            })
            setSortOrder(null);
            return;
        }else {
            if((selectedSortOrder === null) || (selectedSortOrder === 'null')){
                props.updateSortMethod({
                    sortMethod: selectedSortMethod,
                    sortOrder: true
                })
                setSortOrder(true);
            }else{
                props.updateSortMethod({
                    sortMethod: selectedSortMethod,
                    sortOrder: selectedSortOrder
                })
            }
        }
    }

    function changeSortOrder (selectedSortMethod) {
        if((selectedSortMethod === 6) || (selectedSortMethod === 5)){
            return;
        }
        selectSortMethod (selectedSortMethod, !sortOrder);
        setSortOrder(!sortOrder);
    }

    return (
        <div className='menu active'>
            <div className='menu_filter_container' onClick={(e) => e.stopPropagation()}>
                <div className='sort_title'>
                    <p className='sort_title_title'>Сортировка</p>
                    <div 
                        className={(sortOrder === null) || (sortOrder === 'null') ? 'select_order no_select_order cursor-pointer' : sortOrder ? 'select_order true_select_order' : 'select_order false_select_order'} 
                        onClick={() => changeSortOrder(sortMethod)}
                        />
                </div>
                <div className='sort_method' onClick={() => {selectSortMethod(6, sortOrder); setSortMethod(6)}}>
                    <p className='sort_method_title'>По приоритету</p>
                    <div className={sortMethod === 6 ? 'd-block selected_method' : 'd-none'}></div>
                </div>
                <div className='sort_method' onClick={() => {selectSortMethod(4, sortOrder); setSortMethod(4)}}>
                    <p className='sort_method_title'>Алфавиту</p>
                    <div className={sortMethod === 4 ? 'd-block selected_method' : 'd-none'}></div>
                </div>
                <div className='sort_method' onClick={() => {selectSortMethod(2, sortOrder); setSortMethod(2)}}>
                    <p className='sort_method_title'>Сроку выполнения</p>
                    <div className={sortMethod === 2 ? 'd-block selected_method' : 'd-none'}></div>
                </div>
                <div className='sort_method' onClick={() => {selectSortMethod(3, sortOrder); setSortMethod(3)}}>
                    <p className='sort_method_title'>Времени на задачу</p>
                    <div className={sortMethod === 3 ? 'd-block selected_method' : 'd-none'}></div>
                </div>
                <div className='sort_method' onClick={() => {selectSortMethod(5, sortOrder); setSortMethod(5)}}>
                    <p className='sort_method_title'>Времени и приоритету</p>
                    <div className={sortMethod === 5 ? 'd-block selected_method' : 'd-none'}></div>
                </div>
                <div className='sort_method' onClick={() => {selectSortMethod(7, sortOrder); setSortMethod(7)}}>
                    <p className='sort_method_title'>Дате создания</p>
                    <div className={sortMethod === 7 ? 'd-block selected_method' : 'd-none'}></div>
                </div>
            </div>
        </div>
    )
}

export default DDSelectFilter