import React, {useState, useRef, useEffect} from "react";
import './InputTimeToTask.css';

const InpuTimeToTask = ({active, updateDataTimeToTaskHours, updateDataTimeToTaskMinutes, timeToTaskHours, timeToTaskMinutes, submitChangeContext}) => {
    const [autoFocusInput, setAutoFocusInput] = useState(true);
    const refSecondInput = useRef(); 
    const refFirstInput = useRef();

    useEffect(() => {
        if(autoFocusInput && active) {
            refFirstInput.current.focus()
        }
    }, [autoFocusInput, active])

    function stopEnter(e) {
        if (e.keyCode === 13) {
            refSecondInput.current.focus()
            // props.setActive(false)
            e.preventDefault()
        }
    }

    function autoFocus(e) {
        if (e.target.value.length >= 2) {
            setAutoFocusInput(false)
            // console.log(e.target.value);
            // submitChangeContext(e.target.value, timeToTaskMinutes)
            refSecondInput.current.focus()
        }
    }

    function normalizeTime(e) {
        if (e.target.id === 'TimeToTask__hours') {
            if (e.target.value > 23) {
                updateDataTimeToTaskHours({timeToTaskHours: 23})
                autoFocus(e)
            } else if (e.target.value < 0) {
                updateDataTimeToTaskHours({timeToTaskHours: 0})
            } else {
                updateDataTimeToTaskHours({timeToTaskHours: e.target.value})
                autoFocus(e)
            }
        } else if (e.target.id === 'TimeToTask__minutes') {
            if (e.target.value > 59) {
                updateDataTimeToTaskMinutes({timeToTaskMinutes: 59})
            } else if (e.target.value < 0) {
                updateDataTimeToTaskMinutes({timeToTaskMinutes: 0})
            } else {
                updateDataTimeToTaskMinutes({timeToTaskMinutes: e.target.value})
            }
        } else {
            e.preventDefault()
        }   
    }

    function checkWords(e) {
        if (e.keyCode === 9) {
            setAutoFocusInput(false)
        } else if ("0123456789".indexOf(e.key) !== -1) {
            normalizeTime(e)
        } else {
            e.preventDefault()
        }
    }

    return (
        <ul className="TimeToTask__list">
            <li className="TimeToTask__item">
                <input
                    ref={refFirstInput}
                    className='TimeToTask__input'
                    type="text"
                    id="TimeToTask__hours"
                    maxLength='2'
                    autoComplete="off"
                    value={timeToTaskHours === 0 ? '' : timeToTaskHours}
                    onKeyPress={e => checkWords(e)}
                    onKeyDown={e => stopEnter(e)}
                    onPaste={(e) => e.preventDefault()}
                    onDrop={(e) => e.preventDefault()}
                    onBlur={() => submitChangeContext && submitChangeContext(timeToTaskHours, timeToTaskMinutes)}
                    onChange={e => normalizeTime(e)}/>
                <p>ч</p>
            </li>
            <li className="TimeToTask__item">
                <input
                    ref={refSecondInput}
                    className='TimeToTask__input'
                    type="text"
                    id="TimeToTask__minutes"
                    maxLength='2'
                    autoComplete="off"
                    value={timeToTaskMinutes === 0 ? '' : timeToTaskMinutes}
                    onKeyPress={e => checkWords(e)}
                    onKeyDown={e => stopEnter(e)}
                    onPaste={(e) => e.preventDefault()}
                    onDrop={(e) => e.preventDefault()}
                    onClick={() => setAutoFocusInput(false)}
                    onBlur={() => submitChangeContext && submitChangeContext(timeToTaskHours, timeToTaskMinutes)}
                    onChange={e => normalizeTime(e)}/>
                <p>мин</p>
            </li>
        </ul>
    )
}

export default InpuTimeToTask;