import request from "../Requests/requests";
// import { loadUserSettings } from "../store/settingsReducer";

export const editDataUser = (payload) => {
    return async () => {
        await request.put('/Account/User/', {
            userId: localStorage.getItem('token'),
            nickname: payload.nickname,
            name: payload.name,
            lastname: payload.lastname,
            birthday: payload.birthday,
            email: payload.email,
            position: payload.position
        })
        // dispatch(loadUserSettings(payload));
    }
}