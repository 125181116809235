import React, { useEffect, useState } from "react"
import './FilterTableHead.css'
import sortIcon from './static/sortIcon.svg'
import sortIconDesc from './static/sortIconDesc.svg'
import sortIconAsc from './static/sortIconAsc.svg'
export interface FilterTableHeadProps {
  filterName: string
  value: string
  orderBy: string
  className?: string
  change?: (e: string) => void
}

function FilterTableHead({ filterName, value, orderBy, className, change }: FilterTableHeadProps) {
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    if (orderBy.includes(filterName)) {
      if (orderBy.includes(filterName + ' asc')) {
        setClickCount(1)
      }
      
      if (orderBy.includes(filterName + ' desc')) {
        setClickCount(2);
      }
    }
    // eslint-disable-next-line
  }, [])

  const clickSort = () => {
    console.log(clickCount);
    setClickCount(clickCount < 2 ? clickCount + 1 : 0)

    if (change) {
      if (clickCount === 0) {
        if (orderBy.split(',')[0].length === 0) {
          change(filterName + ' asc');
        }
        if (orderBy.split(',').length === 1 && orderBy.split(',')[0].length > 0) {
          const newOrderBy = `${orderBy},${filterName} asc`;
          change(newOrderBy);
        }
        if (orderBy.split(',').length === 2) {
          const newOrderBy = orderBy.split(',')[1] + ',' + filterName + ' asc';
          change(newOrderBy);
        }
      }
      if (clickCount === 1) {
        if (orderBy.split(',').length === 1) {
          change(filterName + ' desc');
        }
        if (orderBy.split(',').length === 2) {
          if (orderBy.split(',')[0].includes(filterName)) {
            const newOrderBy = `${filterName} desc,${orderBy.split(',')[1]}`;
            change(newOrderBy);
          }
          if (orderBy.split(',')[1].includes(filterName)) {
            change(orderBy.split(',')[0] + ',' + filterName + ' desc');
          }
        }
      }
      if (clickCount === 2) {
        if (orderBy.split(',').length === 1) {
          change('');
        }
        if (orderBy.split(',').length === 2) {
          if (orderBy.split(',')[0].includes(filterName)) {
            change(orderBy.split(',')[1]);
          }
          if (orderBy.split(',')[1].includes(filterName)) {
            change(orderBy.split(',')[0]);
          }
        }
      }
    }
  }

  return (
    <div
      className={"filter_table_head " + className}
      onClick={() => {
        clickSort();
      }}
    >
      {value}
      {clickCount === 0 && <img className="sort_icon" src={sortIcon} alt='' />}
      {clickCount === 1 && <img className="sort_icon" src={sortIconAsc} alt='' />}
      {clickCount === 2 && <img className="sort_icon" src={sortIconDesc} alt='' />}
    </div>
  );
}

export default FilterTableHead;

