import { useEffect, useRef, useState } from 'react';
import Cookies from 'universal-cookie';
import request from '../Requests/requests';

const API_URL = process.env.REACT_APP_API_BASEURL ?? 'https://api-demo.timetask.ru/api/';
const cookies = new Cookies();



const getTimeToRefresh = () => {
    const date = new Date();
    date.setMinutes(date.getMinutes() + 4);
    return date.toISOString();
  };
  
//   const timeRemaining = () => {
//     const dateUntil = new Date(localStorage.getItem('liveRefreshUntil') || '');
//     const dateNow = new Date();
//     const diff = dateUntil.getTime() - dateNow.getTime();
//     return diff;
//   };
  
  const getToken = async (setIsAuth: React.Dispatch<React.SetStateAction<boolean>>) => {
    try {
      const response = await request.post(`${API_URL}Account/refresh`, {
        refreshToken: cookies.get('refreshToken')
      });
      localStorage.setItem('liveRefreshUntil', getTimeToRefresh());
      localStorage.setItem('authToken', response.data.token);
      cookies.set('refreshToken', response.data.refreshToken, { maxAge: 24 * 60 * 60 * 7 });
      setIsAuth(true);
    } catch (error) {
      console.error('Failed to refresh token:', error);
      setIsAuth(false);
    }
  };
  
  const useAuthToken = () => {
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(true);
    const refRefresh = useRef<NodeJS.Timeout | null>(null);
  
    const interval = () => {
      if (!refRefresh.current) {
        refRefresh.current = setInterval(async () => {
          await getToken(setIsAuth);
        }, 4 * 60 * 1000);
      }
    };
  
    useEffect(() => {
      setLoader(true)
      const checkAuth = () => {
        const liveRefreshUntil = localStorage.getItem('liveRefreshUntil');
        if (liveRefreshUntil && new Date(liveRefreshUntil).getTime() > Date.now()) {
          setIsAuth(true);
          setLoader(false);
          interval();
        } else {
          getToken(setIsAuth).then(() => setLoader(false));
        }
      };
  
      checkAuth();
      return () => {
        if (refRefresh.current) {
          clearInterval(refRefresh.current);
        }
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
    return { isAuth, loader, setIsAuth };
  };
  
  export default useAuthToken;