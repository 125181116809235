import request from "../Requests/requests";
import { removeTodoIncoming} from "../store/incomingTodosReducer";
import { removeTodoToday } from "../store/todosReducer";
import { getTimeAllTodos } from '../asyncActions/getTimeAllTodos';
import { getCountTime } from "../asyncActions/StatisticsActions/getCountTime";
import { getSpentTime } from "./SpentTimeTask/getSpentTime";
import { getTodosIncoming } from "./getIncomingTodos";
import { getTodos } from "./getTodosToday";
import { getCalendarTodos } from "./CalendarService/getCalendarTodos";

export const completeTodo = (payload) => {
    const dataLoad = {pageSize: 10, pageIndex: 1};
    return async dispatch => {
        await request.put('/Task/Completed?Id=' + payload.id);
        dispatch(removeTodoToday(payload.id));
        dispatch(removeTodoIncoming(payload.id));
        setTimeout(() => {
            dispatch(getCalendarTodos(payload.dateFromCalendarPage));
            dispatch(getTodosIncoming(dataLoad));
            dispatch(getTodos());
            dispatch(getTimeAllTodos());
            dispatch(getCountTime());  
            dispatch(getSpentTime());      
        }, 500);
    } }