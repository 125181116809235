import request from "../../Requests/requests";
import { activateTimer } from "../../store/RunTimeTaskResults/RunTimeResults";

export const findActiveTimer = () => {
    return async dispatch => {
        await request.get('/Timer', { params: {
            UserId: localStorage.getItem('token')
        }})
        .then(response => response.data)
        .then(data => dispatch(activateTimer({isTimerActive: true, taskId: data.taskId, startTime: data.startTime})))
    }
}