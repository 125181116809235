import React, { useEffect, useState } from "react";
import Loader from "../Loader/Lodaer";
import TodoList from "./TodosList";
import TodosAddApp from "./TodosAddApp";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import Filter from "../Filter/Filter";
import { ReactComponent as AddTaskPlus } from "../icons/svg/add-task-plus.svg";
import { ReactComponent as AddTaskPlusMobile } from "../icons/svg/add-task-plus__mobile.svg";
import { ReactComponent as ModalTitleImg} from "../DropDownMenu/SelectRepeatTemplate/static/repeatTemplate_icon.svg"
import { addOnContextState } from "../store/contextMenuTaskReducer";
import { getRepeatTemplates } from "../asyncActions/RepeatTemplatesService/getRepeatTemplates";
import { useConnectionHubContext } from "../Context/connectionHubContext";
import ConverterUTC from "../ConverterUTC/ConverterUTC";
import ModalWindow from "../ModalWindow/ModalWindow/ModalWindow";
import DefaultInput from "../UIcomponents/DefaultInput/DefaultInput";
import InputSelect from "../UIcomponents/InputSelect/InputSelect";
import DropDown from "../UIcomponents/DropDown/DropDown";

interface TodosRepeatProps {
    setIcon: Function, 
}

interface TemplatesState {
    templatesState: {
      repeatTemplate: []
    }
}

interface SettingsUserState {
  settings: {
      settings: []
  }
}

interface PayloadDropDown {
  element: string | number,
  index: number
}

export default function TodosRepeatTemplate({setIcon}:TodosRepeatProps) {
  const dispatch = useDispatch();
  const templates = useSelector((state: TemplatesState) => state.templatesState.repeatTemplate)
  const settingsUser = useSelector((state: SettingsUserState) => state.settings.settings)
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [date, setDate] = useState(new Date());
  const connection = useConnectionHubContext();
  const dropDownArr = ['День', 'Неделя', 'Месяц', 'Год']
  const [isDropDown, setIsDropDown] = useState(false)
  const [intervalPeriod, setIntervalPeriod] = useState(dropDownArr[0])

  const updateData = (value: boolean) => {
    setIsOpen(value)
  }

  useEffect(() => {
    if (settingsUser.length !== 0) {
      setDate(ConverterUTC({ method: 'object', settingsUser: settingsUser }))
    }
  }, [settingsUser]);

  useEffect(() => {
    connection.updateHub?.on("Receive", (userId: string) => {
      if (userId === localStorage.getItem('token')) {
        dispatch<any>(getRepeatTemplates())
      }
    });
    return () => {
      connection.updateHub?.off("Receive")
    }

  }, [connection, dispatch])

  const updateTaskList = () => {
    connection.updateHub.invoke("Send", localStorage.getItem('token'))
      .catch(() => console.error());
  }  

  useEffect(() => {
    dispatch<any>(getRepeatTemplates()).then(() => setLoading(false))
    setIcon(<ModalTitleImg style={{ width: '14px', fill: '#5932EA' }} />)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", checkFastCombinations);
    return () => {
      document.removeEventListener("keydown", checkFastCombinations);
    };
  });

  function checkFastCombinations(e: KeyboardEvent) {
    if (e.altKey && e.keyCode === 77) { //shift + m - открыть/закрыть меню добавления задачи
      setIsOpen(!isOpen)
    }
    if (e.keyCode === 27) {
      setIsOpen(false)
    }
  }
  
  const updateIntervalPeriod = (payload: PayloadDropDown) => {
    setIntervalPeriod(payload.element)
  }

  return (
    <>
      <ModalWindow
        modalShow={false}
        disabledCloseBtn={true}
        title={'Повторять задачу'}
        position={'center'}
        titleType={'default'}
      >
        <section>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <p>Повторять с интервалом</p>
            <DefaultInput inputType="number" placeHolder="1"></DefaultInput>
            <InputSelect title={intervalPeriod} onClickFunc={() => setIsDropDown(!isDropDown)} isActiveChildren={isDropDown}>
              <DropDown optionArr={dropDownArr} isActive={isDropDown} updateIsActive={setIsDropDown} onClickFunc={updateIntervalPeriod} defaultValue={intervalPeriod}/>
            </InputSelect>
          </div>
          <div>
            <p>Время</p> 
          </div>
          <div>
            <ul>
              <li style={{display:'flex', flexDirection: 'row', gap: '8px', alignItems: 'center'}}>
                <input type="radio" />
                <p>Никогда</p>
              </li>
              <li style={{display:'flex', flexDirection: 'row', gap: '8px', alignItems: 'center'}}>
                <input type="radio"/>
                <p>Дата</p>
                <DefaultInput inputType="number"></DefaultInput>
              </li>
              <li style={{display:'flex', flexDirection: 'row', gap: '8px', alignItems: 'center'}}>
                <input type="radio"/>
                <p>После</p>
                <DefaultInput inputType="number"></DefaultInput>
                <p>раз</p>
              </li>
            </ul>
          </div>
        </section>
      </ModalWindow>
      <div className="todo-list">
        <div className="todo-list__container">
          <div id='todosMenu' className="task-actions-container to_the_end">
            <div className="d-flex">
              <div className={isOpen ? 'addTaskBtn__hide' : 'addTaskBtn'} onClick={() => setIsOpen(!isOpen)}>
                <AddTaskPlus className="addTaskPlus" />
                <AddTaskPlusMobile className="addTaskPlus-Mobile" />
              </div>
              <Filter />
            </div>
          </div>
          {isOpen && (
            <div>
              <TodosAddApp repeatTemplate={true} datePayload={date} updateTaskList={updateTaskList} autofocus={true} updateData={updateData}></TodosAddApp>
            </div>
          )}

          {
            loading ? <Loader /> :
              <ul className="todo-list__list">
                {templates.length ? (<div>
                  {templates.map((templates: object, index: number) => {
                    dispatch(addOnContextState({ contextIsOpen: false, contextTaskId: templates.id }))
                    return (
                      <TodoList
                        updateTaskList={updateTaskList}
                        completeBtn
                        timerDisplay
                        fromToday
                        todos={templates}
                        key={index} />
                    )
                  })}
                </div>) : loading ? null : (<p className="todo-list__is-null">Ваш список повторяющихся задач пуст</p>)}
              </ul>
          }
        </div>
      </div>
    </>
  )
}
