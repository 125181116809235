import './index.css';
import React, { useState } from "react";

const DDSelectPriority = (props) => {
    const [isActive, setIsActive] = useState(null)
    const priorityArray = [{name: 'Без приоритета', id: 3, class: 'priority-0'}, 
                           {name: 'Высокий', id: 0, class: 'priority-1'}, 
                           {name: 'Средний', id: 1, class: 'priority-2'}, 
                           {name: 'Низкий', id: 2, class: 'priority-3'}]
    
    function getPriorityItem(value) {
        
        props.updateDataPriority({
            priority: value
        })
        props.setActive(false)
    }
    
    return(
        <div className={props.active ? 'menu active' : 'menu'} onClick={() => props.setActive(false)}>
            <div className={props.path === 'addTask' ? 'priority__container' : 'priority__container__edit-task'} onClick={e => e.stopPropagation()}>
                <ul className='priority__list'>
                    {priorityArray.map((priority, index) => 
                    <li key={index} 
                        className={priority.name === props.priority ? 'priority__item active-priority' : index === isActive ? 'priority__item active-priority' : 'priority__item'} 
                        onClick={(e) => getPriorityItem(priority.id) & setIsActive(index)}>
                        <div className='priority__box'>
                            <div className={priority.class}></div>
                            <p className='priority__decription'>{priority.name}</p>
                        </div>
                    </li>)}
                    
                </ul>
            </div>
        </div>
    )
}

export default DDSelectPriority;