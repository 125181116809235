import React, { forwardRef, useImperativeHandle } from "react";
import request from "../../../../Requests/requests";
import { useState, useEffect } from "react";
import InputSelect from "../../../../UIcomponents/InputSelect/InputSelect";
import DropDown from "../../../../UIcomponents/DropDown/DropDown";
import { useSelector } from "react-redux";
import { SettingsUserState } from "../../../../Types/Types";
import Button from "../../../../UIcomponents/Button/Button";
import useTimeZone from "../../../../customHooks/useTimeZone";
// import Button from "../../../../UIcomponents/Button/Button";

interface MainSettingsProps {
    isDisabled: Function,
    isChangedFunction: Function,
    fromClose: Function,
    updateDataMainSetting: Function
}

export interface MainSettingsData {
    getMainSettingsData: () => {
        currentTimeZone: number | null,
        currentTimeZoneId: string |null | undefined,
        isTimeTracking: boolean | undefined,
        timeZone: number | null,
        timeZoneId: string | null | undefined,
        userId: string
    }
}

type TimeZoneList = [
    {
        id: string,
        name: string
    }
]

type DetectionTZList = {
    id: string,
    name: string,
    index: number
}

const MainSettings = forwardRef<MainSettingsData, MainSettingsProps>(({isDisabled, isChangedFunction, fromClose}, ref) => {
    // const [subscriptionInfo, setSubscriptionInfo] = useState(null);
    const settings = useSelector((state: SettingsUserState) => state.settings.settings)
    const { isTimeTracking } = settings
    const { getCurrentTimeZone} = useTimeZone(new Date(), settings.timeZoneId ?? '')
    const [checkedTimeTracking, setCheckedTimeTracking] = useState(false);
    const [timeZoneList, setTimeZoneList] = useState<TimeZoneList | []>([]);
    const [isDropdown, setIsDropdown] = useState(false);
    const [pickedTimeZone, setPickedTimeZone] = useState<{element: string, index: number} | null>(null);
    const [windowScreen, setWindowScreen] = useState(window.screen.width);
    const [loaderDetection, setLoaderDetection] = useState(false)
    const styleDD = {
        bottom: '35px',
        marginTop: '0px',
        marginBottom: '15px',
        width: windowScreen < 576 ? '325px' : 'max-content',
        height: '250px'
    }

    useImperativeHandle(ref, () => ({
        getMainSettingsData: () => {
            return {
                currentTimeZone: settings.currentTimeZone,
                currentTimeZoneId: settings.currentTimeZoneId,
                isTimeTracking: checkedTimeTracking,
                timeZone: settings.timeZone,
                timeZoneId: pickedTimeZone !== null ? timeZoneList.find(el => el.name === pickedTimeZone.element)?.id : settings.timeZoneId,
                userId: settings.userId
            }
        }
    }));


    useEffect(() => {
        const handleUpdateScreenWidth = () => {
          setWindowScreen(window.screen.width)
        }
    
        window.addEventListener('resize', handleUpdateScreenWidth);
    
        return () => {
          window.removeEventListener('resize', handleUpdateScreenWidth);
        };
    }, [windowScreen])

    const autoDetectionTimeZone = () => {
        setLoaderDetection(true)
        setTimeout(() => {
            const includesTZContinent:DetectionTZList[] = [] 
            timeZoneList.forEach((el, index) => {
                if (el.name.includes(getCurrentTimeZone()) || el.id.includes(getCurrentTimeZone())) {
                    return includesTZContinent.push({id: el.id, name: el.name, index: index})
                }
            })

            if (includesTZContinent.length > 0) {
                handlePickTimeZone({element: includesTZContinent[0].name, index: includesTZContinent[0].index})
            }
            setLoaderDetection(false)
        }, 300);
    }
    
    
    

    // useEffect(() => {
    //     request.get('Payment/SubscriptionInfo?UserId=' + localStorage.getItem('token'))
    //     .then(response => {
    //         setSubscriptionInfo(response.data)
    //     })
    // }, []);

    // const OnSubscription = () => {
    //     request.post('Payment', {
    //         UserId: localStorage.getItem('token') 
    //     })
    //     .then(response => {
    //         window.location.replace(response.data);
    //     })
    // }

    // const OnCancel = () => {
    //     request.put('Payment/Cancel', {
    //         UserId: localStorage.getItem('token') 
    //     })
    //     .then(response => {
    //         setSubscriptionInfo(response.data);
    //     })
    // }
    
    const getTimeZoneName = () => {
        if (pickedTimeZone !== null && timeZoneList && timeZoneList.length > 0) {
            const i = timeZoneList.find(el => el.name === pickedTimeZone.element)            
            return i?.name ?? 'Выберите часовой пояс'
        } else if (pickedTimeZone === null && settings.timeZoneId !== null) {
            const i = timeZoneList.find(el => el.id === settings.timeZoneId)
            return i?.name ?? 'Выберите часовой пояс'
        } else {
            return 'Выберите часовой пояс'
        }
    }

    const getTimeZoneIndex = () => {
        if (pickedTimeZone !== null) {
            return pickedTimeZone.element
        } else if (pickedTimeZone === null && settings.timeZoneId !== null) {
            const i = timeZoneList.find(el => el.id === settings.timeZoneId)
            return i?.name ?? -1
        } else {
            return -1
        }
    }

    const handlePickTimeZone = (payload: {element: string, index: number}) => {
        setPickedTimeZone(payload)
    }
    
    useEffect(() => {
        request.get('/Settings/TimeZoneList')
        .then(response => {
            setTimeZoneList(response.data);
        })
    }, [])
    
    useEffect(() => {
        setCheckedTimeTracking(isTimeTracking);
    }, [settings, isTimeTracking])
    
    useEffect(() => {        
        if (isTimeTracking !== checkedTimeTracking || (pickedTimeZone !== null && timeZoneList.find(el => el.name === pickedTimeZone.element)?.id !== settings.timeZoneId)) {
            isDisabled(false)
            isChangedFunction(true)
            fromClose('mainSettingsPage')
        } else {
            isDisabled(true)
            isChangedFunction(false)
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [ pickedTimeZone, checkedTimeTracking, isTimeTracking])
    
    return (
        <>
            <div className="main-settings-page__container">
                    <ul className="main-settings-page__list">
                        <li className="main-settings-page__item">
                            <div className="main-settings-page__item-container">
                                <div className="main-settings-page__title">Автоматический учёт времени</div>
                                <div className="main-settings-page__toogle">
                                    <label className="switch">
                                    <input type="checkbox" id="checkedTimeTracking" checked={checkedTimeTracking}  onClick={() => {setCheckedTimeTracking(!checkedTimeTracking)}} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <p className="main-settings-page__description">Автоматически начинается учёт времени, когда открыта задача.</p>
                        </li>
                        <li className="main-settings-page__item">
                            <div className="main-settings-page__item-container" style={windowScreen < 576 ? {flexDirection: "column"} : {}}>
                                <div style={{display: "flex", flexDirection: 'column', alignItems: 'flex-start'}}>
                                    <div className="main-settings-page__title">Часовой пояс</div>
                                    <p className="main-settings-page__description">Выберите часовой пояс вашего региона.</p>
                                </div>
                                <div>
                                    <div style={{display: 'flex', justifyContent:'space-between', gap: '20px'}}>
                                        <Button loading={loaderDetection} title="Определить" btnType="default" btnSize="base" onClickFunc={() => autoDetectionTimeZone()}/>
                                        <div style={{maxWidth: windowScreen < 576 ? '200px' : '250px'}}>
                                            <InputSelect title={getTimeZoneName()} onClickFunc={() => setIsDropdown(!isDropdown)} isActiveChildren={isDropdown} >
                                                <DropDown optionArr={timeZoneList?.map((el: {id: string, name: string}) => el.name) ?? []}
                                                    isActive={isDropdown}
                                                    updateIsActive={() => setIsDropdown(!isDropdown)}
                                                    defaultValue={getTimeZoneIndex()}
                                                    onClickFunc={handlePickTimeZone}
                                                    position={styleDD}
                                                    activeFinder={true}
                                                    placeholderFinder="Введите часовой пояс"
                                                />
                                            </InputSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                
                {
                    // <div>
                    //     <div className="main-settings-page__title">Подписка</div>
                    //     {subscriptionInfo?.status === "succeeded" && 
                    //         <div>
                    //             <div>Подписка уже оформлена</div>
                    //             <div>{subscriptionInfo.cardTitle}</div>
                    //             <div>Срок действия: до {subscriptionInfo.endDate}</div>
                    //             <Button title={"Отменить подписку"} onClickFunc={OnCancel}/>
                    //         </div>
                    //     }
                    //     {subscriptionInfo?.status === "active_cancel" &&
                    //         <div>
                    //             <div>Подписка отменена</div>
                    //             <div>Срок действия: до {subscriptionInfo.endDate}</div>
                    //         </div>
                    //     }
                    //     {(subscriptionInfo?.status === "waiting" && subscriptionInfo?.cardTitle !== null) &&
                    //         <div>
                    //             <div>Ожидайте подтверждения</div>
                    //         </div>
                    //     }
                    //     {(subscriptionInfo?.status === "waiting" && subscriptionInfo?.cardTitle === null) &&
                    //         <div>
                    //             <Button title={"Оформить подписку"} onClickFunc={OnSubscription}/>
                    //         </div>
                    //     }
                    //     {(subscriptionInfo?.status === "no_subscription" || subscriptionInfo?.status === "cancel") && 
                    //         <Button title={"Оформить подписку"} onClickFunc={OnSubscription}/>
                    //     }
                    // </div>
                }
            </div>
        </>
    )
});

export default MainSettings;