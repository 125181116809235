const defaultState = {
    workTime: [],
}

const LOAD_WORK_TIME = "LOAD_WORK_TIME";

export const workTimeReducer = (state = defaultState, action) => {
    switch(action.type) {
        case LOAD_WORK_TIME:
            return {...state, workTime: [action.payload]};
        default:
            return state;
    }
}

export const loadWorkTime = (payload) => ({type: LOAD_WORK_TIME, payload});