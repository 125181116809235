const defaultState = {
    spentTime: [],
}

const LOAD_SPENTTIME_RESULTS = "LOAD_SPENTTIME_RESULTS";

export const spentTimeReducer = (state = defaultState, action) => {
    switch(action.type) {
        case LOAD_SPENTTIME_RESULTS:
            return {...state, spentTime: [action.payload]};
        default:
            return state;
    }
}

export const loadSpentTimeResults = (payload) => ({type: LOAD_SPENTTIME_RESULTS, payload});
