const defaultState = {
    tagsArray: [],
}

const LOAD_ARRAY = "LOAD_ARRAY";
const REMOVE_TAG_FROM_ARRAY = "REMOVE_TAG_FROM_ARRAY";

export const tagsToTaskReducer = (state = defaultState, action) => {
    switch(action.type) {
        case LOAD_ARRAY:
            return {...state, tagsArray: [...action.payload]};
        case REMOVE_TAG_FROM_ARRAY:
            return {...state, tagsArray: state.tagsArray.filter(tagsArray => tagsArray.id !== action.payload)};
        default:
            return state;
    }
}

export const loadArrayTagsToTask = (payload) => ({type: LOAD_ARRAY, payload});
export const removeFromArrayTagsToTask = (payload) => ({type: REMOVE_TAG_FROM_ARRAY, payload})

