const defaultState = {
    topForecast: [],
}

const GET_TOP_FORECAST = "GET_TOP_FORECAST";

export const topForecastReducer = (state = defaultState, action) => {
    switch(action.type) {
        case GET_TOP_FORECAST:
            return {...state, topForecast: [action.payload]};
        default:
            return state;
    }
}

export const loadTopForecastData = (payload) => ({type: GET_TOP_FORECAST, payload});