const defaultState = {
    time: [],
}

const LOAD_DATA_TIME = "LOAD_DATA_TIME"

export const timeAllTodosReducer = (state = defaultState, action) => {
    switch(action.type) {
      case LOAD_DATA_TIME:
        return {...state, time: [action.payload]}
      default:
        return state
    }
  }

export const loadTimeAllTodos = (payload) => ({type: LOAD_DATA_TIME, payload})

