// import { useState } from "react";

// type DataTZ = {
//     region: string,
//     offset: string,
//     dateUTC: string,
// }

type UseTimeZoneReturn = {
    // data: DataTZ | null,
    getCurrentTimeZone: () => string;
    getDetectedTimeZone: () => string;
    checkCurrentTimeZone: () => boolean;
};

const useTimeZone = (initDate?: Date, initTimeZone?: string, lengthZoneName?: string, formatZoneName?: string): UseTimeZoneReturn  => {
    // const [data, setData] = useState<DataTZ | null>(null);
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    
    const getOffsetCurrentTimeZone = () => {
        const date = initDate ?? new Date()
        const offsetInMs = date.getTimezoneOffset() * 60 * 1000;
        const hours = Math.floor(Math.abs(offsetInMs) / 3600000);
        const minutes = Math.abs(Math.floor((offsetInMs % 3600000) / 60000));
        const formattedOffset = [
            String(hours).padStart(2, '0'),
            String(minutes).padStart(2, '0'),
            
        ].join(':');
        
        return offsetInMs > 0 ? `-${formattedOffset}` : `+${formattedOffset}`;
    }
    
    // setData({region: initTimeZone ?? 'null', offset: getOffsetCurrentTimeZone(), dateUTC: getDateUTC()})
    
    const options = {
        timeZoneName: lengthZoneName ?? 'short',
    };

    const getCurrentTimeZone = () => {
        return currentTimezone
    }

    const getDetectedTimeZone = () => {
        const formatter = new Intl.DateTimeFormat(formatZoneName ?? 'en-US', options);
        const parts = formatter.formatToParts(initDate);
        const timeZone = parts.find(part => part.type === 'timeZoneName')?.value;
        
        return `(UTC ${getOffsetCurrentTimeZone()}) ${timeZone}` ?? 'Nothing to detect...'
    }

    const checkCurrentTimeZone = () => {
        if (initTimeZone) {
            return currentTimezone === initTimeZone
        }
        else {
            return true
        }
    }

    return { checkCurrentTimeZone, getCurrentTimeZone, getDetectedTimeZone}

}

export default useTimeZone;