import request from "../Requests/requests";
import { editTag } from "../store/tagsReducer";

export const editTags = (payload) => {
    return async dispatch => {
        await request.put('/Tag/Update', {
                tagId: payload.id,
                tagName: payload.tagName
            })
        dispatch(editTag(payload));
        }
    }