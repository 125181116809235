import request from "../Requests/requests";
import { addTag } from "../store/tagsReducer";
import { getTags } from "../asyncActions/getTag";

export const addTags = (payload) => {
    return async dispatch => {
        dispatch(addTag(payload));
        await request.post('/Tag/', { userId: localStorage.getItem('token'), 
        tagName: payload.tagName});
        dispatch(getTags());
    } 
}