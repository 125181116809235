import request from "../Requests/requests";
import { loadRunTimeResults } from "../store/RunTimeTaskResults/RunTimeResults";
import { loadDataTodo } from "../store/todosReducer";

export const getTodos = () => {
    sessionStorage.setItem('key_FilterMethod', 5);
    sessionStorage.setItem('key_FilterOrder', null);
    return async dispatch => {
        await request.get('/Task', {params : {
            UserId: localStorage.getItem('token'),
            TypeSort: 5,
            IsAscending: true,
            Date: null
        }})
        .then(response => response.data)
        .then(json => dispatch(loadDataTodo(json)))
        .then(res => res.payload.map(q => dispatch(loadRunTimeResults({isTimerActive: q.timerIsActive, taskId: q.id, startTime: q.timerStart}))))
    }
}