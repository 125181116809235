import DDSelectFilter from "../DropDownMenu/SelectFilter/DDSelectFilter";
import { selectFilter } from "../asyncActions/selectFilter";
import { useOutsideClick } from "../customHooks/useOutsideClick";
import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ReactComponent as FilterTask} from "../icons/svg/filter-task.svg";
import { ReactComponent as FilterTaskMobile} from "../icons/svg/filter-task-mobile.svg";

const Filter = () => {
    const ref = useRef();
    const location = useLocation();
    const dispatch = useDispatch();

    const [sortMethodRef, setSortMethodRef] = useState(Number(sessionStorage.getItem('key_FilterMethod')));
    const [sortOrderRef, setSortOrderRef] = useState(sessionStorage.getItem('key_FilterOrder'));

    const [menuActivFilterPicker, setMenuActiveFilterPicker] = useState(false);

    const updateSortMethod = (payload) =>{
        setSortMethodRef(payload.sortMethod);
        setSortOrderRef(payload.sortOrder);
        dispatch(selectFilter(payload.sortMethod, location.pathname, payload.sortOrder))
        sessionStorage.setItem('key_FilterMethod', payload.sortMethod);
        sessionStorage.setItem('key_FilterOrder', payload.sortOrder);
    }

    useOutsideClick(ref, () => setMenuActiveFilterPicker(false), menuActivFilterPicker);

    return(
        <div className="filter-container">
            <div className="filter-button" onClick={(e) => {setMenuActiveFilterPicker(!menuActivFilterPicker); e.stopPropagation()}}>
                Фильтры
                <FilterTask className="filter-task"/>
                <FilterTaskMobile className="filter-task-mobile"/>
            </div>    
                {menuActivFilterPicker ?
                    <div ref={ref}>
                        <DDSelectFilter 
                            active={menuActivFilterPicker}
                            setActive={setMenuActiveFilterPicker}
                            updateSortMethod={updateSortMethod}
                            sortMethodRef={sortMethodRef}
                            sortOrderRef={sortOrderRef}
                        />
                    </div>
                    :
                    null
                }
        </div>        
    )
}

export default Filter