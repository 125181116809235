import React, { useEffect } from "react";
import SubMenu from "../../Submenu/SubmenuApp";
import './NotFoundPage.css';
import { NavLink, useNavigate } from "react-router-dom";
import Button from "../../UIcomponents/Button/Button";

export default function NotFoundPage({setIsDisabledHeaderBtns}) {

    const navigate = useNavigate();
    const stylesButtonLogin = {padding: '10px 20px'}
    const stylesTitleLogin = {lineHeight: 'normal'}
    const stylesButtonReg = {padding: '10px 25px', backgroundColor: 'transparent', border: '1px solid #5932EA'}
    const stylesTitleReg = {color: '#5932EA', lineHeight: 'normal'}

    const handleLoginPage = () => {
        navigate('/')
    }

    const handleRegisterPage = () => {
        navigate('/register')
    }

    useEffect(() => {
        if (setIsDisabledHeaderBtns) {
            setIsDisabledHeaderBtns(false)
        }
    }, [setIsDisabledHeaderBtns])

    return (
        <div className="main-container-view">
            {localStorage.getItem('token') ?
                <div className="submenu__container">
                    <SubMenu></SubMenu>
                </div> : null
            }
                <div className="not-found-page">
                    <div className="not-found-page__container">
                        <div className="not-found-page__info">
                            <p className="not-found-page__description-main">Возможно, устарела ссылка или вы ошиблись, набирая адрес.<br />
                                Предлагаем вернуться <NavLink to="/" onClick={() => setIsDisabledHeaderBtns(true)} className='not-found-page__link-to'>на главную</NavLink>.
                            </p>
                                {
                                    localStorage.getItem('token') ? 
                                        <div className="not-found-page__conatiner-btns">
                                            <p className="not-found-page__description">Возможно, устарела ссылка или вы ошиблись, набирая адрес.<br /><br />
                                                Предлагаем вернуться <NavLink to="/" className='not-found-page__link-to'>на главную</NavLink>.
                                            </p>
                                        </div> : 
                                        <div className="not-found-page__conatiner-btns">
                                            <p className="not-found-page__description">Возможно, устарела ссылка или вы ошиблись, набирая адрес.</p>
                                            <Button title='Регистрация' onClickFunc={handleRegisterPage} stylesButton={stylesButtonReg} stylesTitle={stylesTitleReg}></Button>
                                            <Button title='Войти' onClickFunc={handleLoginPage} stylesButton={stylesButtonLogin} stylesTitle={stylesTitleLogin}></Button>
                                        </div>
                                }
                            <div className="not-found-page__cause">
                                <h1 className="not-found-page__cause-header">404</h1>
                                <p className="not-found-page__cause-description">СТРАНИЦА НЕ НАЙДЕНА</p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}