import request from "../Requests/requests";
import { loadDataTodoCalendar } from "../store/calendarTodosReducer";
import { loadDataTodoIncoming } from "../store/incomingTodosReducer";
import { loadDataTodo } from "../store/todosReducer";
import getDateUTC from "../utils/getDateUTC";

export const selectFilter = (sortMethod, location, sortOrder) => {
    if (sortOrder === null) {
        sortOrder = true
    }

    if (location === '/viewlist') {
        return async dispatch => {
            await request.get('/Task/Incoming', {
                params: {
                    UserId: localStorage.getItem('token'),
                    TypeSort: sortMethod,
                    IsAscending: sortOrder,
                    pageSize: 10,
                    pageIndex: 1
                }
            })
                .then(response => response.data)
                .then(data => dispatch(loadDataTodoIncoming(data.tasks)))
        }
    } else if (location === '/calendar') {
        const currdate = new Date(localStorage.getItem("CurrentDate")).toLocaleDateString().replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1');
        return async dispatch => {
            await request.get('/Task', {
                params: {
                    UserId: localStorage.getItem('token'),
                    TypeSort: sortMethod,
                    IsAscending: sortOrder,
                    Date: currdate
                }
            })
                .then(response => response.data)
                .then(json => dispatch(loadDataTodoCalendar(json)))
        }
    } else {
        return async dispatch => {
            await request.get('/Task', {
                params: {
                    UserId: localStorage.getItem('token'),
                    TypeSort: sortMethod,
                    IsAscending: sortOrder,
                    Date: getDateUTC()
                }
            })
                .then(response => response.data)
                .then(json => dispatch(loadDataTodo(json)))
        }
    }
}