import React, { useState } from "react";
import { removeTagsToList } from "../asyncActions/removeTagToList";
import { editTags } from "../asyncActions/editTag";
import { useDispatch } from 'react-redux/es/exports';
import ModalWindowCloseWarning from "../ModalWindow/ModalWindowCloseWarning";
import { modalWindowContext } from "../Context/modalWindowContext";
import { ReactComponent as HashTag } from "../../src/icons/svg/hash-tags.svg";


export default function TagList(props) {
    const dispatch = useDispatch();
    const [flag, setFlag] = useState(false);
    const [title, setTitle] = useState(props.tags.tagName);
    const [wordsCounter, setWordsCounter] = useState();
    const [isActiveCounter, setIsActiveCounter] = useState(false);
    const [modalShowCheck, setModalShowCheck] = useState(false);
    const maxLenghtOfTitle = 60;
    
    let regInput = /[^\s]/gim;

    const data = {
        warningText: 'Метка не может быть пустой',
        closeWarningFromProp: 'TagsList',
    }

    const changingLabel = (e) => {
        setFlag(false);
        if(title.replace(/\s/g, " ") === ""){
            setTitle(props.tags.tagName.trim() );
            setModalShowCheck(true)
        } else if (regInput.test(title) === true) {
            setTitle(title.trim())
            const newTags = {
                id: props.tags.id,
                tagName: title 
            };
            dispatch(editTags(newTags));
        }
        
    }

    // function checkInputTag(e) {
    //     if(regInput.test(e.target.value) === true) {
    //         checkLenghtOfWords(e.target.value)
    //     } else {
    //         e.preventDefault()
    //     }
    // }

    function checkLenghtOfWords(value) {
            if (value.length === 35) {
                setTitle(value)
                return setWordsCounter(value.length) & setIsActiveCounter(true);
            } else if ((value.length >= 35) & (value.length <= maxLenghtOfTitle)) {
                setTitle(value)
                return setWordsCounter(value.length);
            } else if (value.length < 35) {
                setTitle(value)
                return setIsActiveCounter(false);
            }
    }


    return (
        <>
            <li className="tags-list__item">
                <div className="tags-list__item__container" >
                    <div className="tags-hash__container">
                        <HashTag className="tags-hash"></HashTag>
                    </div>
                    {/* <div className="tags-hash"></div> */}
                    {flag ?
                        <>
                            <form onSubmit={(e) => changingLabel(e)} onBlur={(e) => changingLabel(e)} className="form-edit-tags" id={props.tags.id}>
                                <input className="input-edit-tags" maxLength='60' onChange={e => checkLenghtOfWords(e.target.value)} value={title}   autoFocus />
                                <p className={isActiveCounter ? 'ddselect-alert alertCounterLengthOfTitle ' : 'hide'}>Лимит {wordsCounter}/60 </p>
                                {/* <button className="btn-remove-tags__mobile" type="button" title='Удалить метку' onClick={(e) => {dispatch(removeTagsToList(props.tags.id)); e.stopPropagation()}}></button> */}
                            </form>                        
                        </>
                        :
                        <div className="d-flex justify-content-between" onClick={() => setFlag(true)}>
                            <div className="tags-title">{title}</div>                    
                            {/* <button className="btn-remove-tags" title='Удалить метку' onClick={(e) => {dispatch(removeTagsToList(props.tags.id)); e.stopPropagation()}}></button> */}
                        </div> 
                    }
                                        <button className="btn-remove-tags btn-remove-tags__mobile" title='Удалить метку' onClick={(e) => {dispatch(removeTagsToList(props.tags.id)); e.stopPropagation()}}></button>

                    
                    
                </div>
            </li>
            {modalShowCheck ? <modalWindowContext.Provider value={[modalShowCheck, setModalShowCheck]}>
                    <ModalWindowCloseWarning data={data}/>
                </modalWindowContext.Provider>
                : null
            }
        </>
    )
}
