const defaultState = {
    calendarTodos: [],
}

const ADD_TODO_CALENDAR = "ADD_TODO_CALENDAR"
const REMOVE_TODO_CALENDAR = "REMOVE_TODO_CALENDAR"
const LOAD_DATA_TODO_CALENDAR = "LOAD_DATA_TODO_CALENDAR"

export const calendarTodosReducer = (state = defaultState, action) => {
    switch(action.type) {
      case ADD_TODO_CALENDAR:
        return {...state, calendarTodos: [...state.calendarTodos, action.payload]} 
      case REMOVE_TODO_CALENDAR:
        return {...state, calendarTodos: state.calendarTodos.filter(todos => todos.id !== action.payload)}
      case LOAD_DATA_TODO_CALENDAR:
        return {...state, calendarTodos: [...action.payload]}
      default:
        return state
    }
  }

export const addTodoCalendar = (payload) => ({type: ADD_TODO_CALENDAR, payload})
export const removeTodoCalendar = (payload) => ({type: REMOVE_TODO_CALENDAR, payload})
export const loadDataTodoCalendar= (payload) => ({type: LOAD_DATA_TODO_CALENDAR, payload})