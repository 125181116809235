import React from 'react';
import { ReactComponent as CheckCircle } from "./static/CheckCircle.svg"
import { ReactComponent as PaperPlaneTilt } from "./static/PaperPlaneTilt.svg"
// import { ReactComponent as Copyright } from "./static/copyright.svg"
import './SuccessPage.css'
import { useNavigate } from 'react-router-dom';

export interface SuccessPageProps {
    title: string,
    typeIcon: number
}



const SuccessPage = ({ title, typeIcon }: SuccessPageProps) => {
    const navigate = useNavigate();

    setTimeout(() => {
        navigate('/', { replace: true })
    }, 3000);

    return <>
        <div className='success_container'>
            <div className='success_body'>
                {typeIcon === 1 && <CheckCircle className='success_icon'/>}
                {typeIcon === 2 && <PaperPlaneTilt className='success_icon'/>}
                <div className='success_text'>{title}</div>
            </div>

        </div>
    </>
}

export default SuccessPage;
