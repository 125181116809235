import { allCompleted } from "../store/chekListReducer";
import { getCheckList } from "./getCheckList";
import request from "../Requests/requests";


export const AllCheckListCompleted = (payload) => {
    return async dispatch => {
        await request.put('/Checklist/CompletedAll?TaskId=' + payload)
        dispatch(allCompleted(payload))
        dispatch(getCheckList(payload))
    }

}