import request from "../Requests/requests";
import { addTodoIncoming, loadDataTodoIncoming } from "../store//incomingTodosReducer";

export const getTodosIncoming = (dataLoad, setHasNextPage) => {
    sessionStorage.setItem('key_FilterMethod', 6);
    sessionStorage.setItem('key_FilterOrder', true);
    return async dispatch => {
        await request.get('/Task/Incoming', {params : {
            UserId: localStorage.getItem('token'),
            TypeSort: sessionStorage.getItem('key_FilterMethod'),
            IsAscending: true,
            pageSize: dataLoad.pageSize,
            pageIndex: dataLoad.pageIndex
        }})
        .then(response => response.data)
        .then(data => dataLoad.pageIndex === 1 ? dispatch(loadDataTodoIncoming(data.tasks)) && setHasNextPage(data.hasNextPage) : dispatch(addTodoIncoming(data.tasks)) && setHasNextPage(data.hasNextPage))
    }
}