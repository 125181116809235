const defaultState = {
    todosRemoved: [],
}

const LOAD_DATA_TODO_REMOVED = "LOAD_DATA_TODO_REMOVED"
const ADD_TODO_REMOVED = "ADD_TODO_REMOVED"
const TODO_RESTORE = "TODO_RESTORE"
const CLEAR_CART = "CLEAR_CART"

export const dataTodoRemovedReducer = (state = defaultState, action) => {
    switch(action.type) {
      case LOAD_DATA_TODO_REMOVED:
        return {...state, todosRemoved: [...action.payload]}
      case ADD_TODO_REMOVED:
        return {...state, todosRemoved: [...state.todosRemoved, ...action.payload]}
      case TODO_RESTORE:
        return {...state, todosRemoved: state.todosRemoved.filter(todosRemoved => todosRemoved.id !== action.payload)}
      case CLEAR_CART:
        return {...state, todosRemoved: state}
      default:
        return state
    }
  }

export const loadRemovedTodo = (payload) => ({type: LOAD_DATA_TODO_REMOVED, payload})
export const addTodoRemoved = (payload) => ({type: ADD_TODO_REMOVED, payload})
export const restoreTodoAction = (payload) => ({type: TODO_RESTORE, payload})
export const clearCart = (payload) => ({type: CLEAR_CART, payload})