import { useDispatch } from "react-redux";
import { useState } from "react";
import { removCheckList } from "../asyncActions/removeCheckList";
import { editList } from "../asyncActions/editCheckList";


export default function CheckListContent(checklist) {
    const dispatch = useDispatch();
    const [flag, setFlag] = useState(true);
    const [title, setTitle] = useState(checklist.checklist.title);

    const test = function () {
        if(document.getElementById("edit_check_list").value === ""){
            return;
        }
        dispatch(editList(checklist.checklist, title)); 
        setFlag(!flag);
    }

    return (
        <>
            {flag ? <div className="checkList_content">
                <label 
                    htmlFor={checklist.checklistId}
                    onClick={() => setFlag(!flag)} 
                    > 
                        <p 
                            className={checklist.checklist.isCompleted 
                            ? 
                            "checkList_completed" 
                            : 
                            "checkList_uncompleted"}
                        >
                            {checklist.checklist.title}
                        </p> 
                </label>
                <div className="checkList_content__rm-btn-container">
                    {/* <button className="btn_edit_cheklist" onClick={() => setFlag(!flag)} >Редактировать</button> */}
                    <button className="checkList_content__rm-chklst" onClick={() => dispatch(removCheckList(checklist.checklist))}/>
                </div>
            </div>
                :
                <form onSubmit={() => test()}>
                    <input type='text'  
                            id="edit_check_list" 
                            className='editCheckElement' 
                            value={title} 
                            onBlur={() => setFlag(!flag) & test()} 
                            onChange={e => setTitle(e.target.value)} 
                            autoFocus 
                            required/>
                    {/* <button type="button" className="btn_edit_cheklist" onClick={() => setFlag(!flag)} >Отмена</button>
                    <button type="submit" onClick={() => test()}>Сохранить</button> */}
                </form>
            }
        </>
    )
}