import request from "../Requests/requests";
import { removeCheckList } from "../store/chekListReducer";
import { getCheckList } from "./getCheckList";

export const removCheckList = (payload) => {
    return async dispatch => {
        await request.delete('/Checklist?Id=' + payload.checklistId);
            dispatch(removeCheckList(payload.checklistId));
            dispatch(getCheckList(payload.taskId));
        }
    }