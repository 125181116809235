import React from "react";
import "../index.css";

const NoDataTemplate = (props) => {
    return (
        <div className={props.isOpenInfo ? "info-hidden" : "noData-container"} 
            style={props.style ? {marginTop: props.style.marginTop} : null}>
            <div className="noData-icon" />
            <h2 className="noData-description">нет данных</h2>
        </div>
    )
}

export default NoDataTemplate;