import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { getTags } from "../../asyncActions/getTag";
import MainTagsList from "./MainTagsList";
import Loader from "../../Loader/Lodaer";
import Finder from "../../Finder/finder";


export default function MainTagsApp({taskId, matchRegExp, modalShow, updateTagList, tagList, removeTagFromTagList})  {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const tags = useSelector(state => state.tags.tags);
    const arr = tagList?.map(tag => tag.tagId)
    
    //бесполезный кусок кода ни на что не влияющий
    useEffect(() => {
        dispatch(getTags());
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        setLoading(false);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [getTags])

    return (
        <>
                {loading && <Loader />}
                        {tags.length ? 
                            <ul className="tags__list" id='matchedList'>
                                {
                                    matchRegExp ?
                                    <Finder tags={tags} 
                                        matchRegExp={matchRegExp} 
                                        modalShow={modalShow} 
                                        updateTagList={updateTagList} 
                                        taskId={taskId}
                                        tagList={tagList}
                                        arr={arr}
                                        removeTagFromTagList={removeTagFromTagList}/> :

                                    tags.map((tags, index) => {
                                        return <MainTagsList modalShow={modalShow} 
                                            updateTagList={updateTagList} 
                                            key={index} 
                                            taskId={taskId} 
                                            tags={tags}
                                            inList={arr?.includes(tags.id)}
                                            tagList={tagList}
                                            removeTagFromTagList={removeTagFromTagList}/>
                                    }) 
                                } 
                            </ul>  :
                        loading ? null : (<p className="tags__data-nothing">У вас пока нет меток</p>)}
        </>
    )
    }