import request from "../Requests/requests";
import { getTimeAllTodos } from '../asyncActions/getTimeAllTodos';
import { getCountTime } from "../asyncActions/StatisticsActions/getCountTime";
import { getSpentTime } from "./SpentTimeTask/getSpentTime";
import { getTodos } from "./getTodosToday";
import { getTodosIncoming } from "./getIncomingTodos";
import { getCalendarTodos } from "./CalendarService/getCalendarTodos";

export const copyTodo = (payload) => {
    return async dispatch => {
        await request.post('/Task/Copy', {
            "userId": localStorage.getItem('token'),
            "taskId": payload.id ? payload.id : payload
        });
        dispatch(getTimeAllTodos());
        dispatch(getCountTime());  
        dispatch(getSpentTime());      
        dispatch(getTodos());
        dispatch(getTodosIncoming());
        if (payload?.dateFromCalendarPage) {
            dispatch(getCalendarTodos(payload?.dateFromCalendarPage));
        }
    } }