import request from "../Requests/requests";
import { completedCheckList } from "../store/chekListReducer";
import { getCheckList } from "./getCheckList";


export const CheckListCompleted = (payload) => {       
    return async dispatch => {
        await request.put('/Checklist/Completed?Id=' + payload.checklistId)
        dispatch( completedCheckList( payload ) )
        dispatch( getCheckList( payload.taskId ) )
    }

}