import React, { useState } from "react";
import TagsAddApp from "./TagsAddApp";


export default function TagsAddFunction() {
    const [isOpen, setIsOpen] = useState(false);
    const updateData = (value) => {
        setIsOpen(value)
    }
        return(
            <React.Fragment>
                
                {isOpen ?
                    <div>
                        <TagsAddApp setIsOpen={setIsOpen} updateData={updateData}></TagsAddApp>
                    </div>
                    :
                    <button className="d-flex button-add-tags" onClick={() => setIsOpen(true)}><p className="plus-add" /> Добавить метку</button>
                }
                
            </React.Fragment>
        )
    }
