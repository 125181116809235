import React from "react";
import { ReactComponent as SwipeRemove} from "./SwipeableIcons/swipe-remove-task.svg"
import { removeTodo } from "../asyncActions/removeTodos";
import { useDispatch } from 'react-redux/es/exports';

export default function SwipeToLeftFromTask({data, todoId, updateIsSwipeLeft}) {
    const dispatch = useDispatch();
    return (
        <div onClick={(e) => data.update(false) & e.stopPropagation()} 
            className="wrapper-swiped-left" 
            style={data.swipe ? {display:"flex", 
                                    marginLeft:'auto', 
                                    width:`${data.swipe + 7}px`, 
                                    height: `${data.dynamicHeight(todoId)}px`, 
                                    background: 'linear-gradient(270deg, #F85640 2.63%, #5932EA 138.95%)'} : 
                                    {display: "none"}}>
            <div className="wrapper-swiped-left__content" 
                onClick={(e) => updateIsSwipeLeft(0) & dispatch(removeTodo(todoId)) & data.stopTimer() & e.stopPropagation()}>
                <SwipeRemove />
                <p className="wrapper-swiped-left__description" 
                    style={data.swipe < 200 ? 
                    {display:'none'} : 
                    {animationName: 'fadeIn', 
                    animationDuration: '0.8s', 
                    animationFillMode: 'both'}}>
                        Отпустите для удаления
                </p>
            </div>
        </div>
    )
}