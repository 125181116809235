import React, {useContext} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { modalWindowContext } from "../Context/modalWindowContext";
import { useDispatch } from "react-redux/es/exports";
import { changingSettings } from "../asyncActions/editSettings";
import { editDataUser } from "../asyncActions/editDataUser";
import './indexCloseWarningModal.css';

export default function ModalWindowCloseWarning({data}) {
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useContext(modalWindowContext);
    const navigate = useNavigate();
    const location = useLocation()
    function checkURL() {
        if (data.closeWarningFromProp === 'userDatePage') {
            localStorage.setItem('firstName', data.userSettingsProp.name);
            localStorage.setItem('lastName', data.userSettingsProp.lastname);
            localStorage.setItem('position', data.userSettingsProp.position);
            dispatch(editDataUser(data.userSettingsProp));
            if (data.succesProp) {
                data.succesProp('success-alert-unhide');  
            }
            if (data.disabledProp) {
                data.disabledProp(true);
            }
            if (data.changeFunctionProp) {
                data.changeFunctionProp(false);
            } 
            setModalShow(false)
            navigate('/', {require: true});
        } else if (data.closeWarningFromProp === 'mainSettingsPage') {
            localStorage.setItem('isTimeTracking', data.mainSettingsProp.isTimeTracking)
            dispatch(changingSettings(data.mainSettingsProp));
            data.succesProp('success-alert-unhide')
            data.disabledProp(true)
            setModalShow(false)
            navigate(`${location.state?.path ? location.state.path : '/'}`)
        } else if (data.closeWarningFromProp === 'modalEditTask') {
            data.changeFunctionProp()
        }
    }

    function StashChanges () {
        if (data.closeWarningFromProp === 'modalEditTask') {
            data.stashProp()
            setTimeout(() => navigate(`${location.state?.path ? location.state.path : '/'}`));
        } else {
            setTimeout(() => setModalShow(false), 100);
            data.changeFunctionProp(false);
            data.disabledProp(true);
            navigate(`${location.state?.path ? location.state.path : '/'}`)
            // navigate('/', {require: true});
        }
    }

    function SaveChanges() {
        checkURL();
        }

    return (
        <div id="openModal" class={ modalShow ? "modalCloseWarning" : 'hide'}>
            <div class="modalCloseWarningCloseWarning__dialog">
                <div class="modalCloseWarning__content">
                    <div class="modalCloseWarning__header">
                        <p class="modalCloseWarning__title">Изменение данных</p>
                    </div>
                    <div class="modalCloseWarning__body">    
                        <p className="modalCloseWarning__body-description">{data.warningText} 
                            <span className="modalCloseWarning__body-description__bolt" 
                                style={data.closeWarningFromProp === 'TagsList' ? 
                                {display:'none'} : {display: "inline-block"}}> 
                                Сохранить?
                            </span>
                        </p>
                    </div>
                    <div className="modalCloseWarning__btn-group">
                        {
                            data.closeWarningFromProp === 'TagsList' ? 
                            <button className="modalCloseWarning__btn-group__save" onClick={() => setModalShow(false)}>Ок</button>: 
                            <>
                                <button className="modalCloseWarning__btn-group__close" onClick={() => StashChanges()}>Отмена</button>
                                <button className="modalCloseWarning__btn-group__save" onClick={() => SaveChanges()}>Сохранить</button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};