import request from "../../Requests/requests";
import { loadCountTask } from "../../store/StatisticsStore/TaskCounterReducer";
import { getCountPercentTask } from "../../store/StatisticsStore/TaskPercentCounterReducer";
import getDateUTC from "../../utils/getDateUTC";

export const getCountTime = () => {
    return async dispatch => {
        await request.get('/Statistics/TaskCount/', {params: {
            UserId: localStorage.getItem('token'),
            Date: getDateUTC(), 
        }})
        .then(response => response.data)
        .then(data => dispatch(loadCountTask(data)) && 
            dispatch(getCountPercentTask(data.totalTasks ? (data.completedTasks * 100) / data.totalTasks : 0)))
        }
    }