const defaultState = {
    completedTodosOnRange: []
}

const LOAD_DATA = "LOAD_DATA";

export const completedTodosOnRangeReducer = (state = defaultState, action) => {
    switch(action.type) {
      case LOAD_DATA:
        return {...state, completedTodosOnRange: [action.payload]}
      default:
        return state
    }
  }

export const loadData = (payload) => ({type: LOAD_DATA, payload})