import request from "../Requests/requests";
import { loadTimeAllTodos } from "../store/timeAllTodosReducer";
import getDateUTC from "../utils/getDateUTC";

export const getTimeAllTodos = () => {
    return async dispatch => {
        await request.get('/Time/', {params: {date: getDateUTC(), userId: localStorage.getItem('token')}})
        .then(response => response.data)
        .then(data => dispatch(loadTimeAllTodos(data))  
            )
        }
    }