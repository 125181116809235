import React from "react";
import { ReactComponent as ClockIcon } from "./statis/clock-item.svg";

const Deadlineinput = ({isFocus, removeBtnTrigger, time, checkWords, splitTime, setIsFocus, hideRemoveBtn, removePushedDataTime}) => {
    return (
        <li className='calendar__item calendar__item-indent' style={{cursor: 'default'}}>
            <div className='calendar__get-time-from-input'>
                <ClockIcon />
                <div className={isFocus ? 
                            'calendar__get-time-from-input__field__focused' : 
                            'calendar__get-time-from-input__field'}>
                    <input
                        autoComplete="off"
                        minLength='5' 
                        maxLength='5' 
                        type="text" 
                        placeholder={isFocus ? 
                            "  Например, 14:00" : 
                            "Назначить время" } 
                        className={removeBtnTrigger ? 
                            'calendar__get-time calendar__get-time__shorted' : 
                            'calendar__get-time'} 
                        value={time}
                        onKeyPress={e => checkWords(e)}
                        onChange={e => splitTime(e)}
                        onFocus = {() => setIsFocus(true)}
                        onBlur={() => hideRemoveBtn()}
                        />
                    <span className={removeBtnTrigger ? 
                            'calendar__remove-btn' : 
                            null} 
                        onClick={() => removePushedDataTime()}/>
                </div>
            </div>
        </li>
    )
}

export default Deadlineinput;