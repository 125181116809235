import React from "react"
import './Donat.css'

export interface DonatProps {
  percent: number
}

function Donat({ percent }: DonatProps) {
  const radius = 8;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percent / 100) * circumference;

  return (
    <div>
      <svg className="donat" width="19" height="19">
        <circle
          className="donat__background"
          cx="9.5"
          cy="9.04102"
          r={radius}
        />
        <circle
          className={percent > 66 ? "donat__progress_green" : percent > 33 ? "donat__progress_orange" : "donat__progress_red"}
          cx="9.5"
          cy="9.04102"
          r={radius}
          transform="rotate(-90 9.5 9.04102)"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
          
        />
      </svg>
    </div>
  );
}

export default Donat;