import React, {useState, useContext, useEffect} from "react";
import { modalWindowContext } from "../Context/modalWindowContext";
import Modal from 'react-bootstrap/Modal';
import request from "../Requests/requests";

export default function ChangePasswordPage(props) {
    const [modalShow, setModalShow] = useContext(modalWindowContext);
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [oldPassword, setOldPassword] = useState ('');
    const [saveBtn, setSaveBtn] = useState('true');
    const [errorSavingChanges, setErrorSavingChanges] = useState('');
    const [errorPasswordUpperCase, setErrorPasswordUpperCase] = useState('error-change-email unhide-error-email');
    const [errorPasswordLowerCase, setErrorPasswordLowerCase] = useState('error-change-email unhide-error-email');
    const [errorPasswordDigitCase, setErrorPasswordDigitCase] = useState('error-change-email unhide-error-email');
    const [errorPasswordLength, setErrorPasswordLength] = useState('error-change-email unhide-error-email');
    const [errorPasswordNotMatch, setErrorPasswordNotMatch ] = useState('hide');
    const [loading, setLoading] = useState(false)
    
    let regexpToLowerCase = /[а-яa-z]/gu // eslint-disable-line
    let regexpToUpperCase = /[А-ЯA-Z]/gu // eslint-disable-line
    let regexpToDigitCase = /[\p{Nd}]/gu // eslint-disable-line

    /* eslint-disable-next-line */
    useEffect(() => {
        if ((password === repeatPassword) && 
                (oldPassword.length >= 8) && 
                (password.length >= 8) &&
                (repeatPassword.length >= 8) &&
                (password !== '') && 
                (repeatPassword !== '') && 
                (regexpToDigitCase.test(repeatPassword)) &&
                (regexpToLowerCase.test(repeatPassword)) &&
                (regexpToUpperCase.test(repeatPassword))) {
            setSaveBtn(false)
        } else {
            setSaveBtn(true)
            if ((repeatPassword !== password) && (repeatPassword !== '')) {
                setErrorPasswordNotMatch('error-change-email unhide-error-email')
            } else {
                setErrorPasswordNotMatch('hide')
            }
        }
      });
    
    function checkPassword(e) {
        setPassword(e.target.value)
        toLowerCase(e)
        toUpperCase(e)
        toDigitCase(e) 
        toLengthPassword(e)
    }

    function checkRepeatPassword(e) {
        setRepeatPassword(e.target.value)
        if ((e.target.value !== password) && (e.target.value !== '')) {
            setErrorPasswordNotMatch('error-change-email unhide-error-email')
        } else (
            setErrorPasswordNotMatch('hide')
        )
    }

    function toLowerCase(e) {
        if (regexpToLowerCase.test(e.target.value) === true) {
            setErrorPasswordLowerCase('error-change-email unhide-error-paswword')
        } else {
            setErrorPasswordLowerCase('error-change-email unhide-error-email')
        }
    }

    function toUpperCase(e) {
        if (regexpToUpperCase.test(e.target.value) === true) {
            setErrorPasswordUpperCase('error-change-email unhide-error-paswword')
        } else  {
            setErrorPasswordUpperCase('error-change-email unhide-error-email')
        }
    }

    function toDigitCase(e) {
        if (regexpToDigitCase.test(e.target.value) === true) {
            setErrorPasswordDigitCase('error-change-email unhide-error-paswword')
        } else {
            setErrorPasswordDigitCase('error-change-email unhide-error-email')
        }
    }

    function toLengthPassword(e) {
        if (e.target.value.length >= 8) {
            setErrorPasswordLength('error-change-email unhide-error-paswword')
        } else {
            setErrorPasswordLength('error-change-email unhide-error-email')
        }
    }

    function checkBtn(e) {
        setOldPassword(e.target.value)
        if ((e.target.value !== '') && (e.target.value.length >= 8) && (password !== '') && (repeatPassword !== '')) {
            setSaveBtn(false)
        } else {
            setSaveBtn(true)
        }
    }

    function submitChanges(e) {
        if((e.keyCode === 13) && (saveBtn === false)) {
            pushChanges(password)
        }
    }

    function pushChanges(password) {
        setLoading(true)
        request.put('/Account/ChangePassword/', {userId: localStorage.getItem('token'),
        newPassword: password,
        newPasswordConfirm: repeatPassword,
        oldPassword: oldPassword })
        .then(res => {
            setLoading(false)
            if (res.data.errors) {
                setErrorSavingChanges(res.data.errors)
            } else {
                props.isSuccesAlert('success-alert-unhide')
                setModalShow(false)
            }
        })
        // .catch(function (error) {
        //     if(error.response) {
        //         setLoading(false)
        //         setErrorSavingChanges(error.response.data.errors.OldPassword[0])
        //     }
        // });
    }

    //Проверка перед закрытием окна
    // function checkBeforePushChanges () {
    //     if ((password !== '') || (repeatPassword !== '')) {
    //         alert('Сохраните изменения или отмените их!');
    //     } else {
    //         pushChanges(password);
    //         setTimeout(() => setModalShow(false), 100);
    //     } 
    // }

    return (
    <Modal
        {...props}
        show = {modalShow}
        onHide = {() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >   
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                <p className="change-data__title">Изменение пароля</p> 
                <p className="change-data__description">Измените пароль, привязанный к Вашему аккаунту.</p>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body">
            <div className="change-data__container">
                <form className="change-data__form" onSubmit={pushChanges} onKeyDown={e => submitChanges(e)}>
                    <div className="change-data__box">
                        <p className="change-data__box__title">Новый пароль</p>
                        <input type="password" 
                                id="new_password" 
                                className="settings-profile__other change-data__input" 
                                value={password} 
                                placeholder='******'
                                autoComplete="new-password"
                                onChange={e => checkPassword(e)} />
                        <p className={errorPasswordUpperCase}>* требуются буквы в верхнем регистре</p>
                        <p className={errorPasswordLowerCase}>* требуются буквы в нижнем регистре</p>
                        <p className={errorPasswordDigitCase}>* требуются цифры</p>
                        <p className={errorPasswordLength}>* длина пароля минимум 8 символов</p>
                    </div >
                    <div className="change-data__box">
                        <p className="change-data__box__title">Подтвердите новый пароль</p>
                        <input type="password" 
                                id="confirm_password" 
                                className="settings-profile__other change-data__input" 
                                value={repeatPassword} 
                                placeholder='******'
                                autoComplete="new-password" 
                                onChange={e => checkRepeatPassword(e)} />
                        <p className={errorPasswordNotMatch}>* не совпадают Пароли</p>
                    </div>
                    <div className="change-data__box">
                        <p className="change-data__box__title">Текущий пароль</p>
                        <input type="password" 
                                id="confirm_pass" 
                                className="settings-profile__other change-data__input" 
                                required
                                autoComplete="new-password" 
                                onChange={e => checkBtn(e)} />
                        <p className={errorSavingChanges ? 'error-change-email unhide-error-email' : 'hide'}>* {errorSavingChanges.OldPassword}</p>
                    </div>
                </form>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div className="settings-page__btn-group">
                        <button className="settings-page__btn-close" onClick={() => setModalShow(false)}>Отмена</button>
                        <button type='submit' 
                            className="settings-page__btn-save" 
                            disabled={saveBtn} onClick={() => pushChanges(password)}>
                                {loading ? <div className="centered-btn-spinner"><div class="btn-lds-ring"><div></div><div></div><div></div><div></div></div></div> : 'Сохранить'}
                        </button>
                    </div>
        </Modal.Footer>
    </Modal>
    );
}