import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { addTags } from "../asyncActions/addTag";
import Validation from "../UIcomponents/Validation/Validation";

export default function TodosAddApp(props) {
    const dispatch = useDispatch();
    const [tagName, setTagName] = useState('');
    const [saveBtn, setSaveBtn] = useState(true);
    const [wordsCounter, setWordsCounter] = useState();
    const [isActiveCounter, setIsActiveCounter] = useState(false);
    const escapedPattern = tagName.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const tags = useSelector(state => state.tags.tags);
    const maxLenghtOfTitle = 60;
    let arrErrors = new Array([]);
    const [dataErrors, setDataErrors] = useState([]);
    
    useEffect(() => {
        if (tagName === '') {
            setSaveBtn(true)
        } else {
            setSaveBtn(false)
        }
    }, [tagName])

    useEffect(() => {
        function checkLengthOfTag() {
            if (tagName.length === 35) {
                return setWordsCounter(tagName.length) & setIsActiveCounter(true);
            } else if ((tagName.length >= 35) & (tagName.length <= maxLenghtOfTitle)) {
                return setWordsCounter(tagName.length);
            } else if (tagName.length < 35) {
                return setIsActiveCounter(false);
            }
        }

        checkLengthOfTag()
    }, [tagName])

    const handleSubmit = (e) => {
        setDataErrors([])
        if (new RegExp (escapedPattern, 'i').test(tags.map((tags) => tags.tagName))) {
            arrErrors.push({key: 'Tag', err: 'метка уже существует'})
            setDataErrors(arrErrors)
        } else if (tagName.trim() === '') {
            arrErrors.push({key: 'Tag', err: 'метка не может состоять из пробелов'})
            setDataErrors(arrErrors)
            setTagName('')
        }else {
            dispatch(addTags({tagName: tagName.trim()}))
            setTagName('')
            e.preventDefault()
        }
    }
    
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="add-tag-container">
                    <input type='text' className="input-add-tags" style={dataErrors.length !== 0 ? {marginBottom: '0px'} : null} maxLength='60' value={tagName} onInput={() => setDataErrors([])}  onChange={(e) => setTagName(e.target.value) && setDataErrors([])} placeholder="Добавить метку" autoFocus></input>
                    <p className={isActiveCounter ? 'ddselect-alert alertCounterLengthOfTitle ' : 'hide'}>Лимит {wordsCounter}/60 </p>
                </div>
                <Validation data={dataErrors} keyItem="Tag"/>
                <div className="d-flex btn-add-tags-group">
                    <button className="d-flex button-add-tags" onClick={(e) => handleSubmit(e) & e.preventDefault()} disabled={saveBtn}> 
                        <p className="plus-add" /> Добавить
                    </button>
                    <button className="button-close-add-tags" onClick={() => props.setIsOpen(false)}>Отмена</button>
                </div>
            </form>                
        </div>
    )
}
