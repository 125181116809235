import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'air-datepicker/air-datepicker.css';

import App from './App';
import { store } from './store/index';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
  </Provider>
);
