import React from "react";

const StatisticsButton = ({isActiveToday, isActiveWeek, isActiveMonth, setIsActiveToday, setIsActiveWeek, setIsActiveMonth, onChangePeriodOfStatistic}) => {
    const handleSetPeriod = (value) => {
        console.log(value);
        if (value === 'onToday') {
            setIsActiveToday(true)
            setIsActiveMonth(false)
            setIsActiveWeek(false)
        } else if (value === 'onWeek') {
            setIsActiveWeek(true)
            setIsActiveMonth(false)
            setIsActiveToday(false)
        } else if (value === 'onMonth') {
            setIsActiveMonth(true)
            setIsActiveToday(false)
            setIsActiveWeek(false)
        }
        onChangePeriodOfStatistic(`${value}`)
    }
    return (
        <li className='calendar__item-for-statistics calendar__item-indent-for-statistics'>
            <div className='calendar__get-date-from-btns-for-statistics'>
                <span className="calendar-icon-for-statistic"/>
                <p id="onToday" className={isActiveToday ? 
                        'active-get-date-for-statistic get-today-date-for-statistic' : 
                        'get-today-date-for-statistic'} 
                    onClick={(e) => handleSetPeriod(e.target.id)}> 
                    за сегодня
                </p>
                <div className='calendar__separator-for-statistics'></div>
                <p id="onWeek" className={isActiveWeek ? 
                        'active-get-date-for-statistic get-week-date-for-statistic' : 
                        'get-week-date-for-statistic'} 
                    onClick={(e) => handleSetPeriod(e.target.id)}>
                    неделя
                </p>
                <div className='calendar__separator-for-statistics'></div>
                <p id="onMonth" className={isActiveMonth ? 
                        'active-get-date-for-statistic get-month-date-for-statistic' : 
                        'get-month-date-for-statistic'} 
                    onClick={(e) => handleSetPeriod(e.target.id)}>
                    месяц
                </p>
            </div>
        </li>
    )
}

export default StatisticsButton;