import request from "../../Requests/requests";
import { loadDataTodoCalendar } from "../../store/calendarTodosReducer";

export const getCalendarTodos = (date) => {
    sessionStorage.setItem('key_FilterMethod', 5);
    sessionStorage.setItem('key_FilterOrder', null);
    console.log('Date from get Calendar: ' + date);
    return async dispatch => {
        await request.get('/Task', {
            params: {
                UserId: localStorage.getItem('token'),
                TypeSort: 5,
                IsAscending: true,
                Date: date
            }
        })
            .then(response => response.data)
            .then(json => dispatch(loadDataTodoCalendar(json)))
    }
}