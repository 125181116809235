
const DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

function isLeapYear(year) {
    return !((year % 4) || (!(year % 100) && (year % 400)));
}

function getDaysInMonth(date) {
    const month = date.getMonth();
    const year = date.getFullYear()

    if (isLeapYear(year) && month === 1) { //проверяем февраль (1) на високосность
         return DAYS_IN_MONTH[month] + 1;
    } else {
        return DAYS_IN_MONTH[month];
    }

}

function getDayOfWeek(date) {  //начинаем неделю не с нуля(Воскресенье), а с единицы (Понедельник )
    const dayOfWeek = date.getDay();

    if (dayOfWeek === 0) return 6;

    return dayOfWeek - 1;
}

export default function getMonthData(year, month) {
    const result =[];
    const date = new Date(year, month);
    const daysInMonth = getDaysInMonth(date);
    const monthStartsOn = getDayOfWeek(date);
    let day = 1;

    for (let i = 0; i < (daysInMonth + monthStartsOn) / 7; i++) {
        result[i] =[];
        for (let j = 0; j < 7; j++) { // 7 - кол-во дней в неделе
            if ((i === 0 && j < monthStartsOn) || day > daysInMonth) {
                result[i][j] = undefined;
            } else {
                result[i][j] = new Date(year, month, day++);            
            }
        }        
    }

    return result;
}