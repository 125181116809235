const defaultState = {
    countPercentTask: null,
}

const GET_PERCENT_OF_TASK = "GET_PERCENT_OF_TASK";

export const percentCounterReducer = (state = defaultState, action) => {
    switch(action.type) {
        case GET_PERCENT_OF_TASK:
            return {...state, countPercentTask: action.payload};
        default:
            return state;
    }
}

export const getCountPercentTask = (payload) => ({type: GET_PERCENT_OF_TASK, payload});