import React from "react"
import { useDispatch } from "react-redux";
import { removAllCheckList } from "../../asyncActions/removeAllChekList";
import { AllCheckListCompleted } from "../../asyncActions/AllCompletedCheckList";

const DDSelectActionToCheckList = (props) => {
    const dispatch = useDispatch();

return (
    <div className={props.active ? 'menu active' : 'menu'} onClick={() => props.setActive(false)}>
        <ul className='checklist-box__control-btns'>
            <li className="checklist-item">
                <span className="checklist-remove-btn__bg"></span>
                <button className="checklist-remove-btn"  
                    onClick={(e) => dispatch(removAllCheckList(props.taskId)) & props.setActive(false) & e.stopPropagation()}>
                    Удалить чек-лист
                </button>
            </li>
            <li className="checklist-item">
                <span className="checklist-completed-btn__bg"></span>
                <button className="checklist-completed-btn" 
                    onClick={(e) => dispatch(AllCheckListCompleted(props.taskId)) & props.setActive(false) & e.stopPropagation()}>
                    Выполнить всё
                </button>
            </li>
            {/* <button className="checklist-remove-btn checklist-remove-btn__bg"  
                    onClick={(e) => dispatch(removAllCheckList(props.taskId)) & props.setActive(false) & e.stopPropagation()}>
                    Удалить чек-лист
            </button>
            <button className="checklist-completed-btn" 
                    onClick={(e) => dispatch(AllCheckListCompleted(props.taskId)) & props.setActive(false) & e.stopPropagation()}>
                    Выполнить всё
            </button> */}
        </ul>
    </div>
)
}

export default DDSelectActionToCheckList;
