import request from "../../Requests/requests";
import { activateTimer } from "../../store/RunTimeTaskResults/RunTimeResults";

export const timerPush = (payload) => {
    return async dispatch => {
        await request.put('/Timer', {taskId: payload.taskId, 
                                                userId: localStorage.getItem('token'),
                                                timerTime: payload.startTime,
                                                timerIsActive: payload.timerIsActive})
            .then(() => dispatch(activateTimer({isTimerActive: payload.timerIsActive, taskId: payload.taskId, startTime: payload.startTime})))
    }
}