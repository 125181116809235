import request from "../../Requests/requests";
import { loadData } from "../../store/StatisticsStore/CompletedTodosOnRangeReducer";

export const getCompletedTodosOnRange = (payload) => {
    return async dispatch => {
        await request.get('/Statistics/Completed/', {
            params: {UserId: localStorage.getItem('token'),
            DateStart: payload.DateStart, 
            DateEnd: payload.DateEnd }
        })
        .then(response =>{
            const data = response.data;
            dispatch(loadData(data.tasks))
        })
        
    }
}
