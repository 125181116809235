import request from "../Requests/requests";

export const ResetPassword = async (payload) => {
    return(
        await request.put('/Account/ResetPassword', {
            userId: payload.userId,
            code: payload.code,
            password: payload.newPassword,
            passwordConfirm: payload.confirmPassword
        })
    )
}