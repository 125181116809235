import request from "../Requests/requests";
import { editCheckList } from "../store/chekListReducer";
import { getCheckList } from "./getCheckList";

export const editList = (checkList, title) => {
    const payload = {
        checklistId: checkList.checklistId,
        taskId: checkList.taskId,
        isCompleted: checkList.isCompleted,
        title: title
    }
    return async dispatch => {
        await request.put('/Checklist', {
            id: payload.checklistId,
            title: payload.title
        })
        dispatch(editCheckList(payload));
        dispatch(getCheckList(payload.taskId));
    }
}