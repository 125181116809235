const defaultState = {
    countTask: [],
}

const LOAD_COUNTER_TASK = "LOAD_COUNTER_TASK";

export const taskCounterReducer = (state = defaultState, action) => {
    switch(action.type) {
        case LOAD_COUNTER_TASK:
            return {...state, countTask: [action.payload]};
        default:
            return state;
    }
}

export const loadCountTask = (payload) => ({type: LOAD_COUNTER_TASK, payload});

