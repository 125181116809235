import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as FeedbackIcon } from "../icons/svg/header/feedback_header.svg";
import { ReactComponent as EmailIcon } from "../icons/svg/Feedback/email.svg";
import { ReactComponent as UserIcon } from "../icons/svg/Feedback/user.svg";
import { ReactComponent as SuccesIcon } from "../icons/svg/Feedback/succes.svg"
import { ReactComponent as PinIcon} from '../icons/svg/Feedback/pin.svg';
import ModalWindow from '../ModalWindow/ModalWindow/ModalWindow';
import DefaultInput from '../UIcomponents/DefaultInput/DefaultInput';
import Validation from '../UIcomponents/Validation/Validation';
import './Feedback.css'
import request from '../Requests/requests';
import IconThumbNail from '../UIcomponents/IconThumbNail/IconThumbNail';
import Loader from '../Loader/Lodaer';

export default function Feedback() {
  const [windowScreen, setWindowScreen] = useState(window.screen.width);
  const [modalShow, setModalShow] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [success, setSuccess] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('')
  const [pictures, setPictures] = useState<Blob[]>([])
  const [dataErrors, setDataErrors] = useState<object[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const refUploadFiles = useRef<typeof HTMLElement>(null);
  const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg']
  const errArr = [
    'введите Имя', 'введите email', 'напишите свой вопрос'
  ]
  const keyArr = [
    'Name', 'Email', 'Description'
  ]
  let arrErrors:  object[] = [];
  let emailReg: RegExp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/

  const handleOpen = (e: Event) => {
    setModalShow(true)
    e.stopPropagation()
  }
  
  const updateName = (value: string) => {
    setName(value)
  }

  const updateEmail = (value: string) => {    
    setEmail(value)
  }

  useEffect(() => {
    const handleUpdateScreenWidth = () => {
      setWindowScreen(window.screen.width)
    }

    window.addEventListener('resize', handleUpdateScreenWidth);

    return () => {
      window.removeEventListener('resize', handleUpdateScreenWidth);
    };
  }, [windowScreen])

  useEffect(() => {
    if (!modalShow) {
      setName('')
      setEmail('')
      setDescription('')
      setDataErrors([])
      setPictures([])
    }
  }, [modalShow])

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setModalShow(false)
        setSuccess(false)
      }, 2500);
    }
  }, [success, modalShow])

  const uploadFiles = () => {
    refUploadFiles?.current.click()
  }

  const checkInputs = (value: string[]) => {
    return new Promise<void> (async function(resolve, reject) {
      value.forEach((item, index) => {
        if (item === '') {
          arrErrors.push({key: `${keyArr[index]}`, err: `${errArr[index]}`})
        }
        else { 
          arrErrors.filter((item) => item.key === `${keyArr[index]}`)
        }
      })
      if ((email !== '') && (!emailReg.test(email))) {
        arrErrors.push({key: 'Email', err: 'введите корректный email'})
      }
      setDataErrors(arrErrors)
      if (arrErrors.length === 0) {
        resolve()
      } else {
        reject()
      }
    })
  }
  
  const sendFeedback = (e: Event) => {
    e.stopPropagation()
    checkInputs([name, email, description]).then(() => {
      setLoader(true)
      const form = new FormData();
      form.append("EMail", email)
      form.append("Name", name)
      form.append("Message", description)
      for (let i = 0; i < pictures.length; i++) {
        form.append("Images", pictures[i]);
      }
      request.post('/Feedback/', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
      },
      })
      .then(() => setLoader(false))
      .then(() => setSuccess(true))
    })
  }

  const checkImage = (value: File) => {
    return new Promise<any>(async function (resolve, reject) {
      if ((value.size  * Math.pow(10, -6)) > 5) {
        reject('файл слишком большой, ограничение до 5мб')
      } else if (!allowedFormats.includes(value.type)) {
        reject('можно загрузить только форматы jpeg, jpg, png')
      } else {
        resolve(value)
      }
    })
  }

  const changeUpload = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      checkImage(file).then(() => {
        setPictures(prevstate => [...prevstate, file])        
        setDataErrors(prevstate => [...prevstate.filter((el: object) => el.key !== 'uploadFiles')])
        setLoader(false)
      })
      .catch(err => {
        setLoader(false)
        setDataErrors(prevstate => [...prevstate, {key: 'uploadFiles', err: `${err}`}])
      })
    }
    if (!file) return;
  }

  const clearUpload = (value: any) => {
    if (value) {
      URL.revokeObjectURL(value)
    }
    setPictures(pictures.filter(el => el !== value))
  }
  
  return (
    <> 
      {
        modalShow &&
        <ModalWindow 
          updateModalState={setModalShow}
          modalShow={modalShow}
          logo={true}
          headerSize={20}
          position={'baseline'}
          title={success || loader ? '' : 'Появились вопросы?'}>
            <>
              {
                loader ? <div className='feedback__loader'>
                  <Loader />
                </div> :
                <>
                  {
                    success ?
                    <div className='feedback__success'>
                      <SuccesIcon />
                      <h1>Вопрос успешно отправлен</h1>
                    </div> : 
                    <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                      <p style={{lineHeight: '20px'}}>Опиши свой вопрос, как можно подробнее. <br /> И мы вернёмся с ответом на email.</p>
                          <DefaultInput logo={<UserIcon/>} placeHolder='Имя' inputValue={name} inputType='text' onChangeFunc={updateName}>
                            <Validation data={dataErrors} keyItem='Name'/>
                          </DefaultInput>
                          <DefaultInput logo={<EmailIcon/>} placeHolder='Email' inputValue={email} inputType='email' onChangeFunc={updateEmail}>
                            <Validation data={dataErrors} keyItem='Email'/>
                          </DefaultInput>
                          <div>
                            <textarea className='feedback__description' placeholder='Напиши свой вопрос' onChange={(e) => setDescription(e.target.value)} name="feedback" id=""></textarea>
                            <Validation data={dataErrors} keyItem='Description'/>
                          </div>
                          <div>
                            <div className={pictures.length === 4 ? 'feedback__uploadFiles uploadFiles__disabled' : 'feedback__uploadFiles'}
                              onClick={() => uploadFiles()}>
                              <span>
                                <PinIcon style={{fill: pictures.length === 4 ? '#A4A8B1' : '#232360', width: '20px', height: '20px'}}/>
                              </span>
                              <p>Прикрепить файл</p>
                            </div>
                                {
                                  pictures.length === 4 && <p style={{color: '#A4A8B1', fontSize: '12px', lineHeight: '20px', marginTop: '8px'}}>Загружено максимальное количество файлов</p>
                                }
                            <Validation data={dataErrors} keyItem='uploadFiles'/>
                          </div>
                          {
                            loader && <Loader/>
                          }
                          <input ref={refUploadFiles} accept="image/png, image/jpeg" onChange={(e) => changeUpload(e)} type="file" style={{display: 'none'}}/>
                      {
                        pictures.length > 0 &&
                          <IconThumbNail iconsArray={pictures} removeIcon={clearUpload}/>
                      }
                      <div className='feedback__recomendation'>
                        <p>Мы решим проблему быстрее, если укажешь:</p>
                        <ul>
                          <li className='list'><span className='list__dot'/>шаги и действия, которые привели к проблеме или выявили её</li>
                          <li className='list'><span className='list__dot'/>скриншоты с ошибками</li>
                          <li className='list'><span className='list__dot'/>всё, что поможет нам разобраться с вопросом</li>
                        </ul>
                      </div>
                      <button onClick={(e) => sendFeedback(e)} className='feedback__send'>Отправить</button>
                    </div>
                  }
              </>
              }
            </>
        </ModalWindow>
      }
      <button onClick={(e: Event) => handleOpen(e)} className='feedback__btn'>
        <div>
          <FeedbackIcon className='icon-btn'/>
        </div>
        <p>Задать вопрос</p>
      </button>
    </>
  )
}