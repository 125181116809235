import request from "../Requests/requests";
import { clearCart } from "../store/removedTodosReducer";

export const getClearCart = () => {
    return async dispatch => {
        await request.delete('/Task/ClearCart/', {params: {
            UserId: localStorage.getItem('token'), 
        }})
        dispatch(clearCart(0))  
        }
    }