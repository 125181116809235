import React, { ReactElement } from "react";
import { ReactComponent as OptionArrow } from "./static/icons.svg";
import './InputSelect.css'

interface SelectProps {
    title: string,
    onClickFunc: Function,
    children?: ReactElement,
    isActiveChildren?: boolean
}

const InputSelect = ({title, onClickFunc, children, isActiveChildren}: SelectProps) => {

    return (
        <>
            <span className={`inputSelect ${isActiveChildren ? 'active' : ''}`} onClick={(e: any) => {
                e.stopPropagation()
                onClickFunc()}}>
                <p className="inputSelect__title">{title}</p>
                <OptionArrow className={`inputSelect_logo ${isActiveChildren ? 'active' : ''}`}/>
            </span>
                <div className="inputSelect__optionList">
                    {children}
                </div>
        </>
    )

}

export default InputSelect;