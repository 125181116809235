import request from "../../Requests/requests";
import { getTagsToTask } from "../../asyncActions/TagsToTaskActions/getTagsToTask";

export const addTagsToTask = (payload) => {
    return async dispatch => {
        await request.post('/Task/AddTag', {
            taskId: payload.taskId,
            tagId: payload.tagId
        })
        dispatch(getTagsToTask(payload))
        }
    }