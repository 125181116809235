const defaultState = {
  settings: [],
  avatarUrl: ""
}

const EDIT_SETTINGS = "EDIT_SETTINGS"
const SET_AVATAR_URL = "SET_AVATAR_URL"
const LOAD_USER_SETTINGS = "LOAD_USER_SETTINGS"

export const settingsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case EDIT_SETTINGS:
      return { ...state, settings: action.payload}
    case LOAD_USER_SETTINGS:
      return { ...state, settings: action.payload}
    case SET_AVATAR_URL:
      return {
        ...state,
        avatarUrl: action.avatarUrl
      }
    default:
      return state
  }
}

export const editSettings = (payload) => ({ type: EDIT_SETTINGS, payload })
export const loadUserSettings = (payload) => ({type: LOAD_USER_SETTINGS, payload})
export const setAvatarUrl = (avatarUrl) => {
  return {
    type: SET_AVATAR_URL,
    avatarUrl: avatarUrl
  };
}